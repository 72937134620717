.sg-form-section {
    $gap: 0;
    margin-top: $gap;
}

.quiz-section {
    $gap: ac(100px, 50px);
    padding-top: $gap;
}

.pay-rates-section {
    $gap: 50px;
    padding-top: $gap;
}

.travel-section {
    $gap: ac(150px, 50px);
    padding-top: $gap;
    position: relative;
    z-index: 1;
}

.map-section {
    $gap: 0;
    margin-top: $gap;
    padding-top: ac(151px, 60px);
}

.sg-form {
    width: 100%;
    max-width: 1016px;

    &__download-box {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        row-gap: 15px;
        margin-top: 15px;
    }

    &__download {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 3 - 15px);
        margin-right: 15px;
        background-color: var(--pastel);
        border-radius: 7px;
        padding: ac(40px, 20px) ac(30px, 15px);
        min-height: ac(209px, 170px);
        transition: .3s ease;

        &:hover {
            background-color: var(--tuna);

            .topics__download-title {
                color: var(--white)
            }
        }

        .btn {
            margin-top: auto !important;
            border: 0;

            .arrow-btn {
                background-color: var(--pastel);

                span {
                    transform: rotate(135deg);
                }
            }

            &:hover {
                .arrow-btn {
                    background-color: var(--blue);
                }
            }
        }
    }

    &__download-title {
        font-size: ac(25px, 23px);
        line-height: ac(30px, 26px);
        font-weight: 600;
        letter-spacing: .08px;
        margin-bottom: 10px;
        transition: .3s ease;
    }

    &-title {
        font-size: ac(35px, 30px);
        margin-bottom: 40px;
        color: var(--blue);
        line-height: 1.2;
    }

    &-fields-wr {
        position: relative;
        display: flex;
        margin-top: 26px;
        margin-left: 2px;
        padding: 6px 54px 6px 7px;
        background-color: var(--white);
        border-radius: 7px;
        border: 1px solid var(--pastel);
        z-index: 1;
        margin-bottom: ac(20px, 15px);
    }

    .choices {
        border-left: 1px solid var(--tuna);
        width: 33.3%;
        flex-shrink: 0;
        position: relative;

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: ac(30px, 5px);
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }

        &:first-of-type {
            border: none;
        }
    }

    .choices__inner {
        padding-left: 22px;
        padding-right: ac(52px, 22px);
    }

    &-filters {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        .btn{
            font-size: ac(16px, 14px);
        }
    }

    @mixin tab {
        &__download {
            width: calc(100% / 2 - 15px);
        }
    }

    @mixin mob-lg {
        &__download {
            width: calc(100% - 15px);
        }
    }
}

.quiz-section {
    padding-bottom: ac(86px, 30px);

    .title {
        margin-bottom: 40px;
        font-size: ac(35px, 30px);
        line-height: 1.2;
    }
}

.quiz-list {
    display:grid;
    grid-template-columns: 1fr;
    .first__grid-head{
        background: transparent;
        padding-bottom: 28px;
        align-items: center;

        .first__btn{
            margin: 0;
        }
    }
    .first__grid-content {
        background: transparent;
        div{
            margin-bottom: 15px;
        }
    }

}

.pay-rates-section {
    .container {
        position: relative;
    }

    .title {
        margin-bottom: 40px;
    }
}

.pr-form {
    width: 706px;
    max-width: 80%;
    position: relative;
    z-index: 2;

    &-title {
        font-size: ac(35px, 30px);
        margin-bottom: 40px;
        color: var(--blue);
        line-height: 1.2;
    }

    &-fields-wr {
        position: relative;
        display: flex;
        margin-top: 26px;
        margin-left: 2px;
        padding: 6px 53px 6px 7px;
        background-color: var(--white);
        border-radius: 7px;
        border: 1px solid var(--pastel);
        margin-bottom: -30px;
    }

    .choices {
        border-left: 1px solid var(--tuna);
        width: 50%;
        position: relative;

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: ac(30px, 5px);
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }

        &:first-of-type {
            border: none;
            margin-left: 0;
            max-width: 47.7%;
        }

        &:nth-of-type(2) {
            .choices__inner {
                padding-left: 29px;
            }
        }
    }

    .choices__inner {
        padding-left: 21px;
        padding-right: ac(52px, 22px);
    }
}

.pay-rates-swiper-wr {
    background: var(--tuna);
    color: var(--white);
    padding: ac(108px, 80px) 0 95px;
    position: relative;

    .container {
        position: relative;
    }

    .swiper-buttons {
        position: absolute;
        right: 10.8%;
        top: -3px;
        z-index: 2;
    }
}

.pr-card {
    display: flex;

    &-text-content {
        padding-right: 31px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-title {
        border-bottom: 2px solid var(--blue);
        margin-bottom: 30px;
        font-size: ac(30px, 25px);
        display: inline-block;
        font-weight: 600;
        padding-bottom: 0;
    }

    &-fact {
        font-size: ac(16px, 14px);
        margin-bottom: 5px;
        display: inline-block;
        font-weight: bold;
    }

    h4 {
        font-size: ac(20px, 18px);
        color: var(--pastel);
        margin-bottom: 13px;
    }

    &-description {
        line-height: 1.375;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 40px;
        max-width: 603px;

        p {
            line-height: inherit;
        }
    }

    &-options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        gap: 25px;
        margin-bottom: 33px;
    }

    &-options-title {
        font-size: ac(25px, 20px);
        font-weight: bold;
        margin-bottom: 20px;
    }

    &-options-salary {
        font-size: ac(20px, 18px);
        font-weight: 500;
        color: var(--pastel);
    }

    &-summary {
        color: var(--alice);
        opacity: 0.5;
    }

    &-thumbnail-wr {
        width: 397px;
        max-width: 40%;
        flex-shrink: 0;
        margin-top: 53px;
        align-self: flex-start;
    }

    &-thumbnail-caption {
        margin-bottom: 26px;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        padding-left: ac(14px, 0px);
    }

    &-thumbnail {
        position: relative;
        overflow: hidden;


        &::before {
            content: '';
            display: block;
            padding-top: 71.28%;
        }

        picture {
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: calc(50% - 15px / 2);
                height: 100%;
                object-fit: cover;

            }

            &:last-of-type {
                img {
                    left: auto;
                    right: 0;
                }
            }
        }

    }
}

.pr-shape {
    bottom: 0;
    position: absolute;
    right: 3.1%;
    transform: translateY(32%);
    width: 14.486111%;
    z-index: 1;
}

.travel-section {
    &-content {
        max-width: 836px;
    }

    .title {
        margin-bottom: 38px;
    }

    &-sb-title {
        color: var(--blue);
        margin-bottom: 17px;
        font-size: 16px;
        font-weight: 600;

        &.sb-title-2 {
            font-size: ac(20px, 18px);
            margin-bottom: 20px;
        }
    }

    &-text {
        margin-bottom: ac(40px, 25px);
        max-width: 707px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.375;

        p {
            line-height: inherit;
        }
    }

    &-terms {
        max-width: 603px;

        h4 {
            font-size: 16px;
            margin-bottom: 9px;
        }

        p {
            color: var(--pastel);
            font-size: 13px;
            font-weight: 500;
            line-height: 1.2;
        }
    }

    &-cl-form {
        margin-bottom: ac(66px, 30px);
    }

    &-cl-form-2 {
        margin-bottom: ac(47px, 0px);
    }

    &--org {
        padding-bottom: 0;

        .travel-section-content {
            max-width: 100%;
        }

        .travel-section-cl-form {
            margin-bottom: 0;
        }

        @mixin media 1150 {
            .inputs-row {
                flex-wrap: wrap;

                label {
                    margin-bottom: 25px;
                }
            }
        }
    }
}

.inputs-row {
    margin-bottom: 35px;
    display: flex;
    column-gap: 9px;

    label {
        font-size: ac(30px, 18px);
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
            padding-right: 9px;
            flex-shrink: 0;
        }
    }

    .arrow-btn {
        margin-top: -7px;
        margin-bottom: 4px;
    }
}

.input-field {
    @mixin transparent-bg-input inherit;

    border-bottom: 2px solid var(--blue);
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    flex-grow: 1;
    width: auto;
    min-width: 0;
    line-height: normal;
    height: auto;

    &::placeholder {
        opacity: 0.5;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;

    }
}


.map-section {
    position: relative;

    &-text-content {
        width: 70%;
        max-width: 809px;
        position: relative;
        z-index: 1;
        pointer-events: none;
    }

    .title {
        margin-bottom: 40px;
        pointer-events: all;
        max-width: 92%;
    }

    &-motto {
        font-size: ac(30px, 22px);
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 40px;
        max-width: 603px;
        width: 80%;
        pointer-events: all;
    }

    &-labels {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 60px;
        pointer-events: all;
    }
}

.map-swiper-wr {
    pointer-events: all;
    min-height: ac(350px, 100px);

    .swiper-wrapper {
        align-items: stretch;
    }

    .swiper-slide {
        height: auto;
    }

    .positions__card {
        max-width: 100%;
        width: 397px;
        margin: 0 auto;
        height: 100%;
    }

    .arrow-btn {
        margin-bottom: 20px;
    }
    .swiper{
        padding-bottom: 2px;
    }


}

.map-section-map-wr {
    width: perc(665);
    max-width: 730px;
    position: absolute;
    left: 53.8%;
    top: 0;
    transform: translate(0, -10.5%);

    &::before {
        content: '';
        display: block;
        padding-top: 159.39%;
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.active&:hover {
        .map-pin {
            opacity: 0.5;
        }
    }

    .map-pin {
        cursor: pointer;
        transition: opacity 0.3s;

        &.active {
            opacity: 1 !important;
        }
    }

}

.map-hint {
    position: absolute;
    max-width: 500px;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    text-align: center;
    font-size: ac(25px, 18px);
    font-weight: 600;
    word-break: normal;

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: none;
    }
}

.map {
    &__wrapper {
        position: absolute;
        right: -54px;
        top: 89px;

        @mixin min-media 1920 {
            right: -10vw;
            top: -89px;
        }
    }

    &__container {
        width: 664px;
        height: 1060px;

        svg {
            height: ac(1430px, 880px, 993, 1440);;
            left: ac(-800px, -250px, 993, 1440);
            -o-object-fit: contain;
            object-fit: contain;
            position: absolute;
            top: ac(-513px, -113px, 993, 1440);
            width: ac(2152px, 1300px, 993, 1440);
        }

        .marker {
            cursor: pointer;
            clip-path: url(#map-pin-clip-path);
            transition: opacity .3s ease;
        }

        &.active {
            .marker {
                opacity: 0.4;

                &.active {
                    opacity: 1;
                }

                &.is-slider {
                    opacity: 1;
                }
            }
        }
    }
}


@media (max-width: 992px) {
    .map {
        &__wrapper {
            position: relative;
            right: 0;
            top: 60px;
        }

        &__container {
            width: 100%;
            height: ac(900px, 400px, 320, 992);

            svg {
                top: ac(-565px, -190px, 320, 993);
                left: ac(-600px, -356px, 320, 993);
                width: ac(2300px, 1000px, 320, 993);
                height: ac(1500px, 700px, 320, 993);
            }
        }
    }

    .map-section {
        padding-top: 0;

        &-map-wr {
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            margin: ac(-280px, -100px, 320, 992) auto 0;
            width: 108%;
            transform: translateX(-4%);
        }

        &-text-content {
            max-width: 100%;
            width: 100%;
        }
    }

    .map-swiper-wr {
        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            max-width: fit-content;
        }
    }
}

@media (max-width: 769px){
    .quiz-list{
        .first__grid{
            margin-bottom: 15px;
        }
        .first__grid-content div{
            margin-bottom: 0;
            margin-top: 15px;
        }
    }
  }

@media (max-width: 768px) {
    .sg-form-fields-wr {
        flex-direction: column;
        padding-right: 80px;

        .choices {
            width: 100%;
            border: none;
            border-bottom: 1px solid var(--tuna) !important;

            &:last-of-type {
                border: none !important;
            }
        }

        .first__submit {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .quiz-list {
        grid-template-columns: 1fr;
    }

    .inputs-row {
        flex-wrap: wrap;
        margin-bottom: 0;

        label {
            margin-bottom: 25px;
            width: 100%;
        }

        .input-field {
            max-width: 100% !important;
        }

        .arrow-btn {
            margin: 0 0 0 auto;
        }
    }

    .map-section {
        .title {
            max-width: 100%;
        }

        &-motto {
            max-width: 100%;
            width: 100%;
        }

        .swiper-buttons {
            margin: 0 auto;
        }
    }


}

@media (max-width: 700px) {
    .pr-form-fields-wr {
        flex-direction: column;
        padding-right: 80px;

        .first__submit {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .pr-form {

        .choices__inner {
            padding-left: 22px;
        }

        .choices {
            width: 100% !important;
            max-width: 100% !important;
            border: none;
            border-bottom: 1px solid var(--tuna) !important;

            &:last-of-type {
                border: none !important;
            }

            &:nth-of-type(2) {
                .choices__inner {
                    padding-left: 22px;
                }
            }
        }
    }

    .pr-card {
        flex-direction: column;

        &-thumbnail {
            &-wr {
                max-width: 100%;
                margin: 40px auto 0;
                display: flex;
                flex-direction: column-reverse;
            }

            &-caption {
                margin-bottom: 0;
                margin-top: 25px;
            }
        }

        &-text-content {
            padding-right: 0;
        }
    }

    .pay-rates-swiper-wr {
        .container {
            padding-bottom: 80px;
        }

        .swiper-buttons {
            top: auto;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .pr-form {
        max-width: 100%;
    }

    .pr-shape {
        width: 190px;
        transform: translateY(30%);
        right: 0;
    }

    .pr-card {
        &-options {
            justify-content: flex-start;
        }
    }
}
