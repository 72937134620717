.industries {
	position: relative;
	$gap: 0;
	margin-top: $gap;
	padding-top: 50px;
	padding-bottom: ac(174px, 105px);
	background-color: var(--tuna);
	overflow: hidden;

	&__bg {
		left: 0;
		position: absolute;
		top: ac(121px, 174px, 1024, 1440);
		width: 152%;

		.fade-in {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(var(--bg-color));
			top: 0;
			right: 0;
			animation: heartRateIn 3s linear infinite;
		}

		.fade-out {
			position: absolute;
			width: 120%;
			height: 100%;
			top: 0;
			left: -120%;
			animation: heartRateOut 3s linear infinite;
			background: linear-gradient(to right, rgba(var(--bg-color)) 0%, rgba(var(--bg-color)) 80%, rgba(var(--bg-color-opacity)) 100%);
		}

		@keyframes heartRateIn {
			0% {
				width: 100%;
			}
			50% {
				width: 0;
			}
			100% {
				width: 0;
			}
		}

		@keyframes heartRateOut {
			0% {
				left: -120%;
			}
			30% {
				left: -120%;
			}
			100% {
				left: 0;
			}
		}
	}

	&__top-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__nav-btns {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__nav-btn {
		width: 48px;
		height: 48px;
		background: var(--white);
		border-radius: 7px;
	}

	&__title {
		z-index: 1;
		position: relative;
		color: var(--white);
	}

	&__slider {
		width: 100% !important;
		overflow: visible !important;
	}

	&__wrapper {
		margin-top: 267px;
	}

	.swiper-wrapper {
		position: relative;
		display: flex;
		align-items: flex-start;
		z-index: 2;
	}

	&__slide {
		width: 100%;
		max-width: ac(240px, 200px, 651, 1440);

		&:nth-child(2) {
			margin-top: -119px;
			margin-left: -140px;
		}

		&:nth-child(3) {
			margin-top: 145px;
			margin-left: ac(-87px, -140px, 1024, 1440);
		}

		&:nth-child(4) {
			margin-left: -115px;
			margin-top: ac(-32px, -7px, 1024, 1440);
		}

		&:nth-child(5) {
			margin-top: -251px;
			margin-left: -230px;
		}

		&:nth-child(6) {
			margin-top: 100px;
			margin-left: ac(-88px, -70px, 1024, 1440);
		}

		&:nth-child(7) {
			margin-top: -144px;
			margin-left: ac(-88px, -136px, 1024, 1440);
		}

		&:nth-child(8) {
			margin-top: -22px;
			margin-left: ac(-185px, -130px, 1024, 1440);
		}

		&:nth-child(9) {
			margin-top: 120px;
			margin-left: -120px;
		}

		&:nth-child(10) {
			margin-top: ac(-90px, -130px, 1024, 1440);
			margin-left: ac(-89px, -160px, 1024, 1440);
		}
	}

	&__card {
		flex: 0 0 auto;
		display: flex;
		width: 100%;
		min-height: 90px;
		padding: 14px;
		background-color: var(--white);
		border: 2px solid var(--white);
		border-radius: 7px;
		transition: all 0.3s ease !important;

		&:hover {
			background-color: var(--tuna);
		}
	}

	&__arrow {
		margin: auto -31px -27px auto;
		background-color: var(--blue);

		span {
			margin-left: 3px;
			font-size: ac(30px, 18px);
		}
	}

	&__name {
		margin-right: 5px;
		font-weight: 600;
		font-size: ac(25px, 20px);
		line-height: ac(30px, 24px);
	}

	@media (min-width: 1600px) {
		&__bg {
			width: 100%;
		}
	}

	@mixin tab {
		padding-bottom: ac(100px, 40px);

		&__slide {
			padding-right: 15px;
			padding-bottom: 30px;
			height: auto;
			margin: 0 20px 0 0 !important;
			width: auto;
			max-width: unset;
		}

		.swiper-wrapper {
			flex-direction: row;
			margin-right: -40px;
		}

		&__card {
			height: 100%;
		}

		&__bg {
			top: ac(115px, 90px, 320, 1024);
			width: 100%;
			height: ac(300px, 260px, 320, 1024);
		}

		&__wrapper {
			margin-top: ac(80px, 30px);
		}
	}
}
