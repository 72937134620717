.possibilities {
    position: relative;
    padding: ac(25px, 0px, 1024, 1440) 0;
    $gap: ac(150px, 80px);
    margin-top: $gap;

    &--alt {
        .possibilities {
            &__wrapper {
                max-width: ac(500px, 400px, 768, 1440);
            }

            &__img {
                height: ac(287px, 190px, 768, 1440);
            }
        }
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: ac(510px, 300px, 768, 1440);
        height: ac(320px, 190px, 768, 1440);
    }

    &__wrapper {
        width: 100%;
        max-width: ac(603px, 400px, 768, 1440);
        margin-left: ac(515px, 300px, 768, 1440);
    }

    &__title {
        margin-bottom: ac(30px, 25px);
    }

    &__subtitle {
        font-weight: 600;
        font-size: ac(25px, 20px);
        line-height: ac(30px, 25px);
    }

    p:not(.possibilities__subtitle) {
        margin-top: ac(21px, 15px);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }

    @media (min-width: 1600px) {
        &__img {
            width: ac(1020px, 600px, 1600, 2560);

            height: 450px;
            top: -17%;

            img {
                object-position: top;
            }
        }
    }

    @mixin tab {
        &__wrapper {
            max-width: 100%;
            margin-left: 0;
        }

        &__img {
            margin-bottom: ac(50px, 25px);
            position: relative;
            width: ac(420px, 280px, 320, 768);
            height: ac(260px, 170px, 320, 768);
        }

        &--alt {
            .possibilities__wrapper {
                max-width: 100%;
            }
        }
    }
}
