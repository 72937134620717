@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4q9DbZs.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
		U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4S9DQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4q9DbZs.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
		U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4S9DQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4q9DbZs.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
		U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4S9DQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4q9DbZs.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
		U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4S9DQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4q9DbZs.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
		U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Figtree";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/figtree/v4/_Xms-HUzqDCFdgfMm4S9DQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
		U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?sigpt9');
	src:  url('../fonts/icomoon.eot?sigpt9#iefix') format('embedded-opentype'),
	  url('../fonts/icomoon.ttf?sigpt9') format('truetype'),
	  url('../fonts/icomoon.woff?sigpt9') format('woff'),
	  url('../fonts/icomoon.svg?sigpt9#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }

  .icon-search:before {
	content: "\e90f";
  }
  .icon-cross:before {
	content: "\e90e";
  }
  .icon-arrow-left:before {
	content: "\e90a";
  }
  .icon-arrow-right:before {
	content: "\e90b";
  }
  .icon-arrow-top:before {
	content: "\e90d";
  }
  .icon-arrow-bottom:before {
	content: "\e901";
  }
  .icon-arrow:before {
	content: "\e900";
  }
  .icon-twitter:before {
	content: "\e902";
  }
  .icon-linkedin:before {
	content: "\e903";
  }
  .icon-facebook:before {
	content: "\e904";
  }
  .icon-plus:before {
	content: "\e905";
  }
  .icon-misson-4:before {
	content: "\e906";
  }
  .icon-misson-3:before {
	content: "\e907";
  }
  .icon-misson-2:before {
	content: "\e908";
  }
  .icon-misson-1:before {
	content: "\e909";
  }
  .icon-play:before {
	content: "\e90c";
  }


h1,
h2,
h3 {
	font-weight: 700;
}

h1 {
	font-size: ac(80px, 42px);
	line-height: 1.2;
	letter-spacing: 0.4px;
}

h2 {
	font-size: ac(50px, 38px);
	line-height: 1.2;
}

h3 {
	font-size: ac(35px, 30px);
	line-height: 1.2;
}

a {
	display: inline-block;
	color: var(--tuna);
	transition: 0.3s ease;

	&:hover {
		color: var(--blue);
	}
}

p {
	line-height: 120%;
}

.content-element {
	ul {
		list-style: disc;
		padding-left: 25px;

		li {
			font-size: 16px;
			line-height: 21px;
			letter-spacing: 0.03px;

			&:not(:last-child) {
				margin-bottom: ac(11px, 4px);
			}
		}
	}

	p {
		font-size: 16px;
		line-height: 145%;
	}

	h3 {
		font-size: ac(25px, 22px);
		font-weight: 600;
		line-height: 123%;
	}
}

@mixin mob-sm {
	h1 {
		font-size: 38px;
		line-height: 1.2;
	}
	h2 {
		font-size: 36px;
	}
	h2.result-not-found {
		font-size: 28px;
	}
}
