.trending {
    $gap: ac(152px, 70px);
    margin-top: $gap;

    &__title-box {
		display: flex;
		flex-direction: column;
	}

	&__search {
		position: relative;
        margin-top: ac(39px, 25px);
        max-width: 500px;
		width: 100%;
	}

	&__input {
		width: 100%;
		padding: 7px 60px 7px ac(30px, 15px);
		background: #FFFFFF;
		border: 1px solid #AABCEE;
		border-radius: 7px;
		line-height: ac(46px, 35px);
	}

	&__btn {
		position: absolute;
		right: 7px;
		top: 7px;

        .icon-arrow {
            font-size: 21px;
        }
	}

    &__card-box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-right: -15px;
		margin-top: ac(59px, 30px);
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: ac(39px, 20px) ac(30px, 15px);
        width: calc(100% / 3 - 15px);
        min-height: ac(307px, 240px);
        border: 1px solid var(--tuna);
        border-radius: 7px;
        transition: color .3s ease, transform .4s ease !important;

        .btn {
            margin-top: auto;
        }

        &:nth-child(1) {
            .trending__icon {
                width: ac(112px, 92px);
                height: ac(102px, 82px);
            }
        }
    }

    &__title {
        margin-bottom: ac(20px, 10px);
        font-weight: 700;
        font-size: ac(35px, 28px);
        line-height: ac(42px, 38px);
    }

    &__descr {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: ac(20px, 18px);
        line-height: ac(24px, 22px);
        @mixin max-line-length 4;
    }

    &__icon {
        position: absolute;
        bottom: 0;
        right: 17px;
        width: ac(78px, 68px);
        height: ac(88px, 78px);

        img {
            object-position: right bottom;
            object-fit: contain;
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}
