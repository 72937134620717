.care {
	$gap: ac(150px, 70px);
	margin-top: $gap;
	display: flex;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: 0.3s ease;

		img {
			object-position: center top;

			@mixin media 1281 {
				object-position: right 20% top 0;
			}
		}
	}

	&__tab {
		flex: 0 0 auto;
		width: 100%;
		max-width: ac(522px, 300px, 1024, 1440);
		position: relative;
		transition: all 0.3s ease !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 45px 40px;

		&:first-child {
			align-items: flex-end;
		}

		p {
			writing-mode: vertical-rl;
			transform: rotate(180deg);
			margin-top: 37px;
		}

		&.active {
			max-width: ac(197px, 125px, 1024, 1440);
			cursor: pointer;

			.care__btn {
				opacity: 1;
				visibility: visible;
			}

			.care__bg {
				opacity: 0.3;
			}
		}
	}

	&__btn-box {
		margin-top: auto;
		display: flex;
		flex-wrap: wrap;
		margin-right: -14px;
		margin-bottom: -15px;

		.btn {
			margin-right: 14px;
			margin-bottom: 15px;
		}
	}

	&__btn {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		opacity: 0;
		visibility: hidden;
		transition: 0.3s ease;

		p {
			font-weight: 700;
			font-size: ac(50px, 20px);
			line-height: ac(60px, 24px);
			transition: 0.3s ease;
		}

		.arrow-btn {
			span {
				font-size: ac(30px, 22px);
			}
		}

		&:hover {
			p {
				color: var(--blue);
			}

			.arrow-btn {
				background-color: var(--blue);
			}
		}
	}

	&__col {
		position: relative;
		width: 100%;
		max-width: ac(721px, 600px, 1024, 1440);
		flex: 0 0 auto;
		transition: all 0.3s ease !important;

		&.active {
			.care__content {
				&:nth-child(2) {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}

	&__content {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding: ac(60px, 25px, 1024, 1440) ac(103px, 35px, 1024, 1440)
			ac(95px, 30px, 1024, 1440);
		background-color: var(--alice);
		transition: 0.3s ease;

		&:nth-child(2) {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__icon {
		width: ac(66px, 51px);
		height: ac(66px, 51px);

		img {
			object-position: left;
			object-fit: contain;
		}
	}

	&__title {
		margin-top: ac(21px, 14px);
		letter-spacing: 0.3px;
	}

	&__descr {
		margin-bottom: ac(61px, 40px);
		margin-top: ac(39px, 31px);
		max-width: 400px;
		width: 100%;
		overflow: hidden;
		letter-spacing: 0.1px;

		p {
			font-weight: 600;
			font-size: ac(25px, 20px);
			line-height: ac(30px, 24px);
		}

		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: 355px;
			height: 100%;
		}

		.simplebar-content {
			& > * {
				&:not(:last-child) {
					margin-bottom: ac(30px, 21px);
				}
			}
		}

		.simplebar-track.simplebar-vertical {
			right: 20px;
		}

		.simplebar-scrollbar::before {
			width: 5px;
			background-color: var(--pastel);
			opacity: 1;
		}

		.simplebar-vertical {
			width: 9px;
		}
	}

	@media (min-width: 1441px) {
		&__tab {
			max-width: 40%;

			&.active {
				max-width: 15%;
			}
		}

		&__col {
			max-width: 45%;
		}

		&__descr {
			max-width: 100%;
		}
	}

	@mixin tab {
		flex-direction: column;

		&__col,
		&__tab,
		&__descr {
			max-width: unset;
		}

		&__btn {
			flex-direction: row;
			align-items: center;

			p {
				font-weight: 600;
			}
		}

		&__tab {
			padding: 37px;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			height: 134px;
			justify-content: center;

			&:nth-child(1) {
				.care__btn {
					.arrow-btn {
						transform: rotate(180deg);
					}
				}

				.care__bg {
					img {
						object-position: center 24%;
					}
				}
			}

			&:last-child {
				.care__bg {
					img {
						object-position: center;
					}
				}
			}

			.care__btn {
				margin-left: auto;
				flex-direction: row-reverse;
			}

			p {
				margin-top: 0;
				margin-right: 20px;
				writing-mode: unset;
				transform: unset;
			}

			&.active {
				max-width: unset;
			}
		}

		&__content {
			padding: 60px 38px;
		}
	}

	@mixin mob-lg {
		&__btn-box {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	@mixin mob-sm {
		&__content {
			padding: 40px 15px;
		}
	}
}
