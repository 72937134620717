.commitments {
    $gap: ac(150px, 80px);
    margin-top: $gap;
    position: relative;
    padding-left: 22px;

    .container {
        max-width: 1290px;
    }

    &__decor {
        position: absolute;
        left: 0;
        top: 0;

        &:first-of-type {
            top: -80px;
            left: -8%;
        }

        &:nth-of-type(2) {
            max-width: ac(367px, 240px, 1025, 1440);
            left: -20%;
            top: 76px;
            z-index: -1;

            img {
                transform: rotate(-40deg);
            }
        }

        &:nth-of-type(3) {
            max-width: ac(204px, 150px, 1025, 1440);
            left: -6%;
            top: ac(148px, 110px, 1025, 1440);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        width: fit-content;
        margin-left: auto;
        padding: 60px 0 80px ac(215px, 170px, 1025, 1440);
        position: relative;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: calc(100% + 50vw);
            left: 0;
            top: 0;
            background: var(--pastel);
            z-index: -1;
            border-radius: 5px;
        }
    }

    &__title {
        margin-bottom: 56px;
    }

    &-list {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        row-gap: 37px;
        column-gap: 15px;
        width: fit-content;

        &__item {
            max-width: 294px;

            .icon {
                font-size: 39px;
            }

            &-title {
                margin: 20px 0 5px;
            }

            &-desc {
                &, &>* {
                    font-size: ac(20px, 18px);
                    color: var(--white);
                    line-height: 130%;
                }
            }
        }
    }
}

@mixin media 1200 {
    .commitments {
        &-list {
            grid-template-columns: repeat(2, 1fr);
        }
        padding-left: 0;

        &__content {
            margin-left: 15%;
        }
    }
}

@mixin tab-md {
    .commitments {
        &__decor {
            display: none;
        }

        &__content {
            margin-left: 0;
            padding-left: 0;
            position: static;
        }

        &-list {
            grid-template-columns: repeat(3, 1fr);

            &__item {
                max-width: 100%;
            }
        }
    }
}

@mixin tab-sm {
    .commitments {
        &-list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@mixin mob-lg {
    .commitments {
        &-list {
            grid-template-columns: 1fr;
        }
    }
}