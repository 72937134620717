.gallery {
    $gap: ac(150px, 80px);
    margin-top: $gap;

    &__slider {
        width: 100%  !important;
        overflow: visible !important;
    }

    .swiper-slide {
        display: flex;

        &:nth-child(4n + 1) {
            .gallery__img {
                border-radius: 112px 30px 30px 112px;
            }
        }
     
        &:nth-child(4n + 3) {
            .gallery__img {
                border-radius: 112px 30px;
            }
        }
        &:nth-child(4n + 4) {
            .gallery__img {
                border-radius: 30px 112px 30px 30px;
            }
        }
    }

    &__img {
        width: 100%;
        height: ac(282px, 220px, 1024, 1440);
        overflow: hidden;
        border-radius: 30px;
    }

    @mixin tab {
        &__img {
            height: ac(282px, 180px, 650, 1024);
        }
    } 

    @mixin mob-xl {
        &__img {
            height: ac(240px, 160px, 450, 650);
        }
    }

    @mixin mob {
        &__img {
            height: ac(300px, 240px, 320, 450);
        }
    }
}