.news {
	$gap: ac(145px, 99px);
	padding-top: $gap;
}

.news--care {
	$gap: ac(155px, 80px);
	padding-top: $gap;
}

.news--org {
	$gap: ac(108px, 80px);
	padding-top: $gap;
}

.news {
	&--category {
		.news {
			&__wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;

				&>.btn {
					margin-top: ac(40px, 30px);
					padding: 4px 9px;
				}
			}

			&__card-box {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				gap: ac(40px, 15px) 15px;
				margin-right: -15px;
			}

			&__card {
				width: calc(100% / 3 - 15px);
				color: inherit;
				height: auto;

				&:nth-child(4n + 1) {
					.news__head {
						background-color: var(--blue);
	
						.arrow-btn {
							background-color: var(--white);
	
							span {
								color: var(--tuna);
							}
						}
					}
	
					&:hover {
						.news__head {
							background-color: var(--tuna);
						}
						.news__name {
							color: var(--blue);
						}
					}
				}

				&:nth-child(4n + 2) {
					.news__head {
						background-color: var(--tuna);
	
						.arrow-btn {
							background-color: var(--blue);
	
							span {
								color: var(--tuna);
							}
						}
					}
	
					.news__name {
						color: var(--blue);
					}
	
					&:hover {
						.news__head {
							background-color: var(--blue);
						}
	
						.news__name {
							color: var(--tuna);
						}
					}
				}

				&:nth-child(4n + 3) {
					.news__head {
						background-color: var(--pastel);
	
						.arrow-btn {
							background-color: var(--tuna);
	
							span {
								color: var(--white);
							}
						}
					}
	
					.news__name {
						color: var(--tuna);
					}
	
					&:hover {
						.news__head {
							background-color: var(--tuna);
						}
	
						.news__name {
							color: var(--pastel);
						}
					}
				}

				&:nth-child(4n + 4) {
					.news__head {
						background-color: var(--alice);
	
						.arrow-btn {
							background-color: var(--white);
	
							span {
								color: var(--tuna);
							}
						}
					}
	
					.news__name {
						color: var(--blue);
					}
	
					&:hover {
						.news__head {
							background-color: var(--tuna);
						}
	
						.news__name {
							color: var(--pastel);
						}
					}
				}
			}

			&__head {
				min-height: ac(228px, 180px);
			}
		}
	}

	&--care {
		.news__wrapper {
			gap: ac(40px, 30px);
			justify-content: space-between;
		}

		.news__slider-box {
			margin-top: 0;
		}

		.news__title {
			max-width: 400px;
		}
	}

	&--org {

		.news__card {
			max-width: 397px;
		}

		.news__head {
			min-height: 228px;
		}

		.news__slider-box {
			display: flex;
			gap: 15px;
			justify-content: center;
		}

		.news__wrapper {
			max-width: 809px;
			margin: 0 auto;
		}

	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__buttons {
		display: flex;
		gap: 15px;
		flex-wrap: wrap;
	}

	&__title {
		margin-right: 20px;
		width: 100%;
		max-width: calc(100% - 300px);
	}

	&__btn {
		margin-left: auto;
	}

	&__slider-box {
		display: flex;
		margin-top: ac(40px, 30px);
		width: 100%;
	}

	&__slider {
		width: 100% !important;
		overflow: visible !important;
	}

	&__slide {
		&:nth-child(3n + 1) {
			.news__card {
				.news__head {
					background-color: var(--blue);

					.arrow-btn {
						background-color: var(--white);

						span {
							color: var(--tuna);
						}
					}
				}

				&:hover {
					.news__head {
						background-color: var(--tuna);
					}
					.news__name {
						color: var(--blue);
					}
				}
			}
		}
        
		&:nth-child(3n + 2) {
			.news__card {
				.news__head {
					background-color: var(--tuna);

					.arrow-btn {
						background-color: var(--blue);

						span {
							color: var(--tuna);
						}
					}
				}

				.news__name {
					color: var(--blue);
				}

				&:hover {
					.news__head {
						background-color: var(--blue);
					}

					.news__name {
						color: var(--tuna);
					}
				}
			}
		}

		&:nth-child(3n + 3) {
			.news__card {
				.news__head {
					background-color: var(--pastel);

					.arrow-btn {
						background-color: var(--tuna);

						span {
							color: var(--white);
						}
					}
				}

				.news__name {
					color: var(--tuna);
				}

				&:hover {
					.news__head {
						background-color: var(--tuna);
					}

					.news__name {
						color: var(--pastel);
					}
				}
			}
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;

		& > .btn {
			margin-top: 14px;
			min-height: 31px;
			padding: 0 11px;
			border: 0;
			background-color: var(--pastel);

			span {
				line-height: 31px;
				margin-right: 0;
			}
		}
	}

	&__head {
		width: 100%;
		position: relative;
		padding: ac(30px, 25px);
		height: 100%;
		transition: 0.3s ease;

		.arrow-btn {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 29px;
			height: 29px;

			span {
				line-height: 29px;
				font-size: 14px;
			}
		}
	}

	&__name {
		max-width: calc(100% - 25px);
		max-height: ac(168px, 136px);
		font-weight: 700;
		font-size: ac(35px, 28px, 1024, 1440);
		line-height: ac(42px, 34px, 1024, 1440);
		letter-spacing: 0.2px;
		transition: 0.3s ease;
		@mixin max-line-length 4;
	}

	&__descr {
		margin-top: 11px;
		flex: 0 0 auto;
		font-weight: 300;
		font-size: 16px;
		line-height: 22px;
		max-height: 44px;
		height: 44px;
		color: var(--tuna);

		@mixin max-line-length 2;
	}

	@mixin tab {
		&--category {
			.news__card {
				width: calc(100% / 2 - 15px);
			}
		}
    }

	@mixin tab-sm {
		&--org {
			.news__slider-box {
				flex-direction: column;
				gap: 40px;
			}

			.news__card {
				max-width: 100%;
			}
		}
	}

	@mixin mob-xl {
		&__title {
			margin-right: 0;
			max-width: 100%;
		}

		&__btn {
			margin: 0;
		}

		&__btn:not(.news--care .news__btn) {
			margin-top: 40px;
			order: 1;
		}

		&--category {
			.news__card {
				width: calc(100% - 15px);
			}
		}
	}
}
