.team-card {
    max-width: 397px;
    background: var(--white);
    border: 1px solid var(--tuna);
    border-radius: 7px;
    padding: 30px;

    &__img {
        @mixin aspect-ratio 337, 337;
    }

    * {
        color: var(--tuna);
    }

    &__preview {
        position: relative;
        border-radius: 7px;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--blue);
            z-index: 2;
            pointer-events: none;
            transition: 0.3s ease;
            opacity: 0;
        }
    }

    &__icon {
        position: absolute;
        width: 29px;
        height: 29px;
        border-radius: 7px;
        background: var(--tuna);

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 15px;

        top: 15px;
        right: 15px;

        i {
            color: var(--white);
            z-index: 20;
        }
    }

    &__name {
        margin: 16px 0 3px;
        font-size: ac(25px, 22px);
    }

    &__pos {
        font-size: ac(20px, 18px);
        font-weight: 600;
    }

    &__hidden,
    &__excerpt {
        position: absolute;
        bottom: 11px;
        left: 11px;
        right: 11px;
        max-height: 190px;
        max-width: 294px;
        background: var(--white);
        padding: 20px;
        border: 1px solid var(--tuna);
        border-radius: 7px;
        z-index: 5;
        transition: 0.3s ease;
        opacity: 0;

        p {
            font-size: 16px;
            line-height: 134%;
        }
    }

    &:hover {
        .team-card__excerpt {
            opacity: 1;
        }
    
        .team-card__preview:before {
            opacity: 0.5;
        }
    }
}