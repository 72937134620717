.ic-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;

    &-bg {
        position: absolute;
        inset: 0;
        background: var(--tuna);
        opacity: 0.5;
        z-index: 0;
    }

    &__slider-box {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .slider-nav {
            display: flex;
            margin: 15px auto 0;
        }
    }

    &__slider {
        width: 100% !important;

        .swiper-slide {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .ic-popup-title {
                position: relative;
                font-weight: 700;
                font-size: ac(25px, 20px);
                line-height: ac(30px, 24px);
                color: var(--white);
                letter-spacing: 0.2px;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: ac(-7px, -3px);
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--blue);
                }
            }

            .ic-popup-address,
            .ic-popup-tel {
                font-size: 13px;
                line-height: 20px;
                text-decoration: none;
                color: var(--white);

                &:hover {
                    color: var(--blue);
                }
            }

            .ic-popup-address {
                margin-top: ac(17px, 10px);
                font-weight: 400;
                word-break: break-word;
            }

            .ic-popup-tel {
                margin-top: ac(13px, 8px);
                font-weight: 400;
            }
        }
    }

    &-close-btn {
        background: var(--tuna);
        color: var(--white);
        width: 29px;
        height: 29px;
        display: flex;
        line-height: normal !important;
        justify-content: center;
        align-items: center;
        font-size: 12.37px;
        border-radius: 7px;
        position: absolute;
        right: ac(30px, 10px);
        top: ac(30px, 10px);
        transition: 0.3s;
        cursor: pointer;

        i {
            line-height: normal;
        }

        &:hover {
            background: var(--pastel) !important;
        }
    }


    &-container {
        width: 1016px;
        background: var(--white);
        position: relative;
        z-index: 1;
        margin: 0 auto;
        border: 1px solid var(--tuna);
        border-radius: 7px;

        align-self: center;
        display: flex;

        max-height: min(90%, 720px);
        max-width: 95%;

        padding: ac(80px, 55px) ac(83px, 25px) ac(72px, 30px) ac(103px, 20px);
        @mixin media 650 {
            padding-right: 10px;
        }
    }

    &-container-inner {
        max-height: 100%;
        overflow-y: auto;
        scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
        scrollbar-width: 5px;
        width: calc(100% + 15px);
        padding-right: ac(15px, 10px);

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }
        .submit-btn{
            transform: translateY(ac(-7px, 0px));
        }
    }

    &-form {
        h3 {
            font-size: ac(25px, 20px);
            margin-bottom: ac(22px, 15px);
            font-weight: 600;
        }
    }

    &-form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 14px 15px;
        margin-bottom: ac(33px, 25px);
    }

    a {
        color: var(--blue);

        &:hover {
            text-decoration: underline;
        }

    }

    h2 {
        margin-bottom: ac(33px, 25px);
    }

    h3 {
        font-size: ac(25px, 20px);
        margin-bottom: ac(22px, 15px);
        font-weight: 600;
    }
    &-offices-title{
        margin-top: 48px;
        margin-bottom: 27px!important;
    }
    .ic-popup-mail-link{
        color: var(--white);
        text-decoration: none;
        font-size: ac(25px, 20px);
        margin-bottom: 12px;
        font-weight: bold;
        &:hover{
            color: var(--pastel);
            text-decoration: none;
        }
    }
    .offices-list{
        grid-template-columns: repeat(4,1fr);
        margin-bottom: 26px;
        grid-gap: 25px;
        padding-right: ac(8px, 0px);
        a{
            color: var(--white);
            text-decoration: none;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.5384;
            &:hover{
                color: var(--pastel);
            }
        }
        li{
            h3{
                width: fit-content;
                min-width: 0;
                margin-bottom: 9px;
                padding-bottom: 0;
                line-height: 1.66;
            }
            &>a{
                margin-top: 5px;
            }
        }
        @media (max-width: 768px){
            grid-template-columns: 1fr 1fr;
          }
        @media (max-width: 450px){
            grid-template-columns: 1fr;
          }
    }
}

.ic-popup.success {
    display: flex;
    align-items: center;
    padding: 100px 10px;

    .ic-popup {
        &-container {
            background: var(--tuna);
            max-height: 100%;
            height: auto;
        }

    }

    h2 {
        color: var(--pastel);
        margin-bottom: ac(40px, 25px);
    }

    h3 {
        color: var(--white);
        font-size: ac(35px, 20px);
        margin-bottom: 0;
        font-weight: 700;
    }

    .ic-popup-close-btn {
        background: var(--white);
        color: var(--tuna);
    }
}

.ic-popup.ic-popup-refer {
    .ic-popup-mail-link {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .ic-popup {
        &-container {
            background: var(--tuna);
            color: var(--white);

            &-inner {
                overflow-y: auto;
                scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1);
                scrollbar-width: 5px;

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 2px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 3px;
                    cursor: pointer;
                }
            }
        }

        &-close-btn {
            background: var(--white);
            color: var(--tuna);
        }
    }
}

.upload-btn {
    padding: 10px 0 34px;
    display: flex;

    label {
        cursor: pointer;
        color: inherit;
        display: flex;
        align-items: center;
        gap: 20px;

        &:hover {
            text-decoration: underline;
        }

        input {
            display: none;
        }
    }

    div {
        min-height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--blue);
        padding: 5px 10px;
        border-radius: 7px;
        min-width: 141px;
        font-size: 16px;
        font-weight: 500;
    }
}

.checkbox-wr {
    cursor: pointer;
    font-size: 13px;
    display: block;
    margin-bottom: 3px;
    width: fit-content;
    transition: opacity 0.3s;
    font-weight: 400px;

    &:hover{
        opacity: 0.8;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    & > span {
        display: block;
        position: relative;

        a {
            color: var(--blue);
        }

        &::before {
            content: '';
            display: inline-block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid var(--tuna);
            border-radius: 2px;
            margin-bottom: -3px;
        }
    }

    input:checked + span {
        &::before {
            background: var(--blue);
        }
    }

    &.checkbox-wr-white {
        & > span {
            position: relative;

            &::before {
                border: 1px solid var(--white);

            }
        }
    }
}

.upload-status {
    font-size: 16px;
    font-weight: 300;
}

@media (max-width: 1024px) {
    .ic-popup-container {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ic-popup-form-grid {
        grid-template-columns: 1fr;
    }
}
