.care-menu {
    $gap: ac(155px, 80px);
    padding-top: $gap;

    &__buttons {
        margin: 0 auto 40px;
        display: flex;
        justify-content: center;
        gap: 30px;
        position: relative;
        flex-wrap: wrap;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    &__box {
        display: flex;
        gap: 15px;
    }

    &__banner {
        position: relative;
        overflow: hidden;
        background: var(--pastel);
        padding: 42px 30px 46px;
        color: var(--tuna);
        border-radius: 7px;

        &.dark {
            color: var(--white);

            background-color: var(--tuna);
            background-image: url("../images/gloves-bg.png");
            background-repeat: no-repeat;
            background-position: bottom right;

            max-width: 397px;
            width: 100%;
            min-height: 350px;
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;

            .btn {
                width: fit-content;
                margin: auto 0 0;
            }

            .care-menu__title {
                margin-bottom: 32px;
            }

            .care-menu__desc {
                margin-bottom: 78px;
            }
        }

        &.blue {
            &:first-child {
                .care-menu__img {
                    width: ac(424px, 300px);
                    height: ac(345px, 250px);

                    right: -94px;
                    top: -95px;
                    position: absolute;
                }
            }

            &:nth-child(2) {
                .care-menu__img {
                    width: ac(330px, 295px);
                    height: ac(210px, 135px);

                    position: absolute;
                    right: -55px;
                    top: 5px;
                }
            }
        }
    }

    &__title {
        margin-bottom: 25px;
    }

    &__desc {
        font-weight: 600;
        font-size: ac(25px, 22px);
    }

    &__inner {
        display: flex;
        gap: 17px
    }

    .btn {
        flex-shrink: 0;
    }

    &__img {
        position: absolute;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @mixin tab {
        &__box, &__wrap {
            flex-direction: column;
            align-items: flex-start;
            max-width: 100%;
            width: 100%;
        }

        &__inner {
            max-width: 85%;
            flex-direction: column;
            align-items: flex-start;
        }

        &__banner {
            width: 100%;

            &.dark {
                max-width: 100%;
            }
        }
    }

    @mixin mob-lg {
        &__inner {
            max-width: 100%;
        }


        &__banner.blue {
            &:first-child {
                .care-menu__img {
                    right: -85px;
                    bottom: -60px;
                    top: auto;
                }
            }

            &:nth-child(2) {
                .care-menu__img {
                    right: -90px;
                    top: auto;
                    bottom: 0;
                }
            }
        }
    }

    @mixin media 415 {
        &__buttons {
            flex-direction: column;

            .btn {
                min-width: 197px;
                justify-content: space-between;
            }
        }
    }
}