.headlines {
    $gap: ac(128px, 70px);
    margin-top: $gap;

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }


    h2  {
        margin-top: 20px;
        margin-right: 5px;
    }

    &__nav {
        margin-left: auto;
        margin-top: 20px;
    }

    &__slider {
		margin-top: ac(40px, 25px);
		width: 100%;
		display: flex;
		align-items: flex-start;
	}

	&__thumb,
	&__video {
		width: 100% !important;
		height: 100%;
	}

    &__thumb-box,
	&__video-box {
		height: 516px;
	}

    &__video-box {
		width: 100%;
        max-width: ac(603px, 380px, 1024, 1440);
        flex: 0 0 auto;
        margin-right: 15px;

		.swiper-slide {
			display: flex;
			flex-direction: column;

            iframe {
                width: 100%;
                height: ac(315px, 225px);
            }
        }

        .headlines__text-box {
            padding: ac(31px, 15px);
        }
	}


    &__thumb-box {
        width: 100%;

		.swiper-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease;

            iframe {
                width: 100%;
                height: 100%;
                cursor: pointer;
                pointer-events: none;
            }

			&:hover,
			&-thumb-active {
				opacity: 1;
				cursor: pointer;
			}
		}

        .headlines__title {
            max-height: 24px;
            @mixin max-line-length 1;

            padding-right: 2rem !important;
        }

        .headlines__descr {
            margin-top: 10px;
            max-height: 88px;
            @mixin max-line-length 4;

            &>* {
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .headlines__text-box {
            @mixin media 500 {
                display: none;
            }
        }
	}

    &__card {
        display: flex;
        background-color: var(--alice);
    }

    &__text-box {
        position: relative;
        width: 100%;
        padding: ac(20px, 15px) ac(22px, 15px);

        .arrow-btn {
            position: absolute;
            right: 9px;
            top: 9px;
            width: 29px;
            height: 29px;

            span {
                line-height: 29px;
                font-size: 14px;
            }
        }
    }

    &__title {
        font-weight: 600;
        font-size: ac(20px, 18px);
        line-height: ac(24px, 22px);
    }

    &__descr {
        margin-top: 10px;

        &, &>* {
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__img {
        position: relative;
        flex: 0 0 auto;
        width: 206px;
        height: 162px;

        @mixin media 500 {
            height: 100px;
        }

        &::before, &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            content: '';
            width: 53px;
            height: 33px;
            border-radius: 5px;
            background-color: var(--blue);
            display: none;
        }

        &::before {
            content: "\e90c";
            font-family: "icomoon" !important;
            color: var(--white);
            font-size: 15px;
            line-height: 33px;
            margin-left: 2px;
            z-index: 1;
        }
    }

    @mixin tab {
		&__slider {
			flex-direction: column;
		}

        &__video-box {
            margin: 0 auto 20px;
        }

		&__thumb-box {
			width: 100%;
            display: flex;

            .swiper-slide {
                height: auto;
            }
		}

		&__thumb-box,
		&__video-box {
			height: auto;
		}

		&__nav {
			.arrow-btn {
				width: 37px;
				height: 37px;

				span {
					line-height: 37px;
				}
			}
		}

        &__video-box {
            max-width: 600px;
        }

        &__card {
            flex-direction: column-reverse;
            height: 100%;
        }

        &__text-box {
            margin-bottom: auto;
        }

        &__img {
            width: 100%;
        }
	}
}
