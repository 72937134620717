.about-first {
    padding-top: ac(155px, 100px);

    &__heading {
        padding: 60px ac(103px, 60px) 60px 0;
        width: fit-content;
    }

    &__title {
        max-width: 810px;

        @mixin media 1440 {
            max-width: 56.25vw;
        }
    }

    &__images {
        display: grid;
        gap: 15px;
        grid-template-columns: repeat(3,1fr);
        position: relative;
        width: fit-content;
        margin: -250px 0 0 auto;
        min-height: ac(395px, 255px);

        &-banner {
            position: absolute;
            max-width: 147px;
            width: 10.2vw;
            min-width: 95px;
            bottom: 16px;
            right: 0;
        }
    }

    &__img {
        width: 13.25vw;
        height: 13.25vw;

        max-width: 190px;
        max-height: 190px;

        min-width: 120px;
        min-height: 120px;

        &:first-of-type {
            grid-column: 2 / 3;
        }

        &:not(:first-of-type, :nth-of-type(2)) {
            margin-left: -100px;
        }

        img {
            border-radius: 5px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@mixin tab-md {
    .about-first {
        &__title {
            max-width: 640px;
        }

        &__images {
            margin-top: -35px;
        }
    }
}

@mixin mob-lg {
    .about-first {
        &__img {
            &:first-of-type {
                grid-column: auto;
            }

            &:not(:first-of-type, :nth-of-type(2)) {
                margin-left: 0;
            }
        }

        &__images {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: 0;
        }
    }
}

