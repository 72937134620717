.member-first {
    position: relative;
    padding-top: ac(215px, 140px);

    &__heading {
        max-width: 480px;
    }

    &__title {
        margin-bottom: 30px;
    }

    &__contacts {
        margin-bottom: 40px;

        &-link {
            display: block;
            width: fit-content;
            font-weight: 600;
            font-size: ac(25px, 22px);
        }
    }

    &__img {
        width: vw(712);
        height: vw(428);
        max-width: 712px;
        max-height: 428px;
        min-height: ac(375px, 300px);
        right: 0;
        bottom: 6px;
        position: absolute;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: ac(250px, 100px) 0 0 ac(250px, 100px);
        }
    }

    &__socials {
        bottom: 40px;
        right: ac(108px, 40px);
        position: absolute;

        &-link {
            width: ac(88px, 70px);
            height: ac(88px, 70px);
            background: var(--pastel);
            font-size: ac(50px, 46px);
            border-radius: 7px;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: var(--white);
            }
        }
    }

    @mixin tab {
        padding-bottom: ac(380px, 340px, 375, 1024);

        &__img {
            max-width: 600px;
            width: 100%;
            bottom: 0;
        }
    }

    @mixin mob-xl {
        &__img {
            max-width: 90%;
        }
    }

    @mixin min-media 1920 {
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__img {
            position: relative;
        }
    }
}