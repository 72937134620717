.details {
    $gap: 0;
    margin-top: $gap;

    &__wrapper {
        display: flex;
        margin-top: 5px;
        margin-bottom: ac(151px, 75px);
    }

    &__search {
        margin-top: ac(200px, 150px);

        form {
            margin-top: ac(30px, 15px);
            position: relative;
            display: flex;
            padding: 6px;
            width: 100%;
            max-width: 705px;
            background-color: var(--white);
            border-radius: 7px;
            border: 1px solid var(--pastel);

            input {
                width: 100%;
                padding-left: ac(23px, 5px);
                padding-right: ac(30px, 10px);

                &,
                &::placeholder {
                    line-height: 48px;
                    font-size: 18px
                }

                @mixin transparent-bg-input var(--tuna);
            }

            button {
                height: 48px;
                width: 48px;
                align-items: center;

                span {
                    width: 26px;
                    height: 26px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M7 1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM.25 7a6.75 6.75 0 1 1 12.05 4.18l3.26 3.26-1.06 1.06-3.253-3.253A6.75 6.75 0 0 1 .25 7Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                    transition: .3s ease;
                }
            }
        }
    }

    &__col {
        max-width: ac(809px, 610px, 1024, 1440);
        margin-right: ac(118px, 35px, 1024, 1440);
        flex: 0 0 auto;

        .btn {
            margin-top: ac(29px, 20px);
        }
    }

    &__text-box {
        margin-top: ac(56px, 25px);

        a {
            color: var(--pastel);

            &:hover {
                color: var(--blue);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(21px, 15px);
            }

            &:not(:first-child) {
                margin-top: ac(39px, 20px);
            }
        }

        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        h4, h5, h6 {
            font-size: ac(25px, 22px);
            line-height: ac(30px, 28px);
        }

        p, li {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }

        p, ul, ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul, ol {
            li {
                position: relative;
                padding-left: 24px;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    background-color: var(--tuna);
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-counter;

            li {
                counter-increment: my-counter;

                &::before {
                    content: counter(my-counter) ". ";
                    position: absolute;
                    left: 0px;
                    color: var(--tuna);
                    font-weight: 500;
                }
            }
        }

        &--search {
            a {
                display: inline;

                &>* {
                    display: inline;
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                }
            }
        }
    }

    &__soc-box {
		display: flex;
		flex-direction: column;
        margin-top: ac(40px, 30px);

		& > span {
			width: 100%;
			font-weight: 600;
			font-size: ac(20px, 16px);
			line-height: ac(24px, 19px);
		}
	}

    &__soc-row {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		margin-right: ac(-20px, -18px);
		margin-bottom: -10px;
	}

    &__soc {
		display: flex;
		justify-content: center;
		width: ac(40px, 35px);
		height: ac(40px, 35px);
		background-color: var(--pastel);
		border-radius: 7px;
		margin-right: ac(20px, 18px);
		margin-bottom: 10px;

		span {
			font-size: ac(23px, 21px);
			line-height: ac(40px, 35px);
			transition: .3s ease;

			&.icon-twitter {
				font-size: ac(21px, 18px);
			}
		}

		&:hover {
			background-color: var(--blue);

			span {
				color: var(--tuna);
			}
		}
	}

    &__sticky {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__photo {
        position: relative;
        width: ac(405px, 302px, 1024, 1440);
        height: ac(191px, 164px, 1024, 1440);
        border-radius: 5px;
        overflow: hidden;

        .details__soc {
            position: absolute;
            bottom: 7px;
            left: 7px;
            margin: 0;
        }
    }

    &__name {
        margin-top: 20px;
        font-weight: 600;
        font-size: ac(25px, 18px);
        line-height: ac(30px, 24px);
    }

    &__position {
        margin-top: 10px;
        margin-bottom: 16px;
        font-weight: 600;
    }

    &__link {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__slider-box {
        display: flex;
        margin-top: ac(39px, 25px);
    }

    &__slider {
        width: 100% !important;
    }

    &__slide {
        .positions__card {
            height: 100%;
            width: 100%;
        }
    }

    @mixin tab {
        &__slider-box {
            flex-wrap: wrap;
        }

        &__wrapper {
            flex-direction: column-reverse;
            margin-top: ac(60px, 35px);
        }

        &__photo {
            border-radius: 5px;
            width: 100%;
            max-width: ac(403px, 340px, 375, 1024);
            height: ac(191px, 164px, 375, 1024);
        }

        &__col {
            margin-right: 0;
            max-width: 100%;
        }
    }
}
