.testimonials--about {
    padding-bottom: ac(140px, 80px);
    $gap: ac(140px, 80px);
    padding-top: $gap;
    position: relative;

    .reviews__bg {
        top: calc(50% + 4px);
    }

    .reviews__circle {
        top: 50%;
    }

    .testimonial {
        display: flex;
        min-height: 265px;

        &__img {
            max-width: 294px;
            width: 100%;
            @mixin aspect-ratio 294,294;
            border-radius: ac(147px, 44px) 0 0 0;
        }

        &__content {
            padding-top: 52px;
            max-width: 500px;
            margin-left: 12%;
        }

        &__quotes {
            font-size: 58px;
            font-family: 'Arial', sans-serif;
            margin-bottom: 4px;
            display: block;
        }

        .content-element {
            margin-bottom: 25px;
        }

        &__name {
            font-size: ac(20px, 18px);
            margin-bottom: 8px;
            font-weight: 600;
        }

        &__pos {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .testimonials-slider {
        max-width: 1020px;
    }
}

@mixin tab-sm {
    .testimonials--about {
       .testimonial {
           flex-direction: column;
           align-items: center;

           &__content {
               margin: 40px 0 0 0;
               padding-top: 0;
           }
       }
    }
}

