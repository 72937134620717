.meet-team {
    $gap: ac(150px, 80px);
    padding-top: $gap;

    &__heading {
        max-width: 603px;
        margin-bottom: 43px;
    }

    &--member {
        padding-bottom: 10px;

        .content-element {
            font-size: 16px;
            line-height: 136%;
        }
    }

    .container {
        position: relative;
    }

    &__subtitle {
        margin: 40px 0 22px;
        font-size: ac(25px, 22px);
        line-height: 120%;
    }

    .decors {
        max-width: 360px;
        width: 100%;
        position: absolute;
        right: 0;
        top: -40px;
    }

    &__decor {
        position: absolute;

        &:first-child {
            max-width: 9.2vw;
            left: -53px;
            top: 15px;
        }

        &:nth-of-type(2) {
            width: 9.7vw;
            height: 9.7vw;
            border-radius: 50%;
            background: var(--pastel);
            left: 38px;
            top: -24px;
        }

        &:nth-of-type(3) {
            max-width: 18.2vw;
            z-index: -1;
            top: -45px;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 15px;
    }

    .team-card {
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    &__slider {
        display: none;

        .swiper-slide {
            max-width: 397px;
        }
    }

    &--member {
        h2 {
            margin-bottom: 35px;
        }
    }

    &--careers {
        .container {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }

        .slider-nav {
            margin: ac(0px, 25px, 1023, 1024) 0 0 auto;
        }

        .meet-team {
            &__slider {
                width: 100%;
                display: flex;
            }
        }

        .meet-team-slider {
            width: 100% !important;

            .swiper-slide {
                max-width: unset;
            }
        }

        .team-card {
            padding: ac(30px, 15px) ac(30px, 15px) ac(38px, 15px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__preview {
                &::before {
                    display: none;
                }
            }

            &__img {
                width: 100%;
                height: ac(335px, 258px, 320, 1440);
                border-radius: 7px;
                overflow: hidden;
            }

            &__pos {
                margin-bottom: 21px;
            }

            &__btn-box {
                margin-top: auto;
                position: relative;
                display: flex;
                width: 100%;
            }

            &__hidden {
                bottom: 45px;
                left: 0;
                right: auto;
                max-height: unset;
                visibility: hidden;

                &:last-child {
                    left: 0;
                }
            }

            &__btn {
                display: inline-flex;
                align-items: center;
                cursor: pointer;

                .arrow-btn {
                    margin-right: 10px;
                    width: 29px;
                    height: 29px;
                    background-color: var(--pastel);

                    span {
                        font-size: 14px;
                        line-height: 29px;
                        color: var(--tuna);
                    }
                }

                &>span {
                    &:last-child {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                &--weak {
                    margin-left: ac(65px, 15px);
                }

                .arrow-btn {
                    span {
                        transform: rotate(90deg);
                        transition: .3s linear;
                    }
                }

                &:hover {
                    .arrow-btn {
                        background-color: var(--blue);

                        span{
                            transform: none;
                        }
                    }
                }

                &--weak:hover~.team-card__hidden--weak,
                &--str:hover~.team-card__hidden--str {
                    opacity: 1;
                    visibility: visible;

                }

                &--weak:hover~.team-card__hidden--weak
                {
                    left: auto!important;
                    right: 0!important;
                }
            }
        }
    }
}

@mixin tab {
    .meet-team {
        &__list {
            display: none;
        }

        &__slider {
            display: flex;
            flex-wrap: wrap;

            .meet-team-slider {
                width: 100%;
            }
        }

        &--careers {
            .slider-nav {
                margin: 25px auto 0;
                order: 1;
            }
        }
    }
}

@mixin tab-sm {
    .meet-team {
        &__decor {
            display: none;
        }
    }
}

@mixin mob-xl {
    .meet-team {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}