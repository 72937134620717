.benefits {
    $gap: ac(150px, 80px);
    padding-top: $gap;

    &__img {
        max-width: 507px;
        width: 100%;
        margin-right: ac(81px, 0px, 1024, 1440);

        @mixin aspect-ratio 507, 712;

        @mixin tab {
            height: 600px;
            max-width: 600px;
            margin-bottom: 40px;
        }

        @mixin mob-lg {
            height: auto;
        }
    }

    &__wrap {
        display: flex;

        @mixin tab {
            flex-direction: column;
        }
    }

    &__title {
        max-width: 603px;
        margin-bottom: 38px;
    }

    &-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px ac(53px, 20px, 1024, 1440);
        max-width: 780px;

        @mixin mob-lg {
            grid-template-columns: 1fr;
        }

        &__item {
            max-width: 294px;

            @mixin tab {
                max-width: 100%;
            }

            &-icon {
                max-width: 69px;
                height: 38px;
                margin-bottom: 15px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: left;
                }
            }

            &-title {
                font-weight: 600;
                font-size: ac(25px, 22px);
                line-height: 120%;
                margin-bottom: 21px;
            }

            &-desc {
                font-weight: 500;
                font-size: 16px;
                line-height: 135%;
            }
        }
    }

    &--org {
        .benefits__img {
            @mixin aspect-ratio 507, 622;
        }

        .benefits-list {
            grid-row-gap: 32px;
            grid-column-gap: 48px;
        }

        .benefits__title {
            margin-bottom: 40px;
        }

        .benefits-list__item-icon {
            max-width: 69px;
            margin-bottom: 15px;

            img {
                object-position: left;
            }
        }
    }

    @mixin min-media 1600 {
        &__wrap {
            justify-content: center;
        }

        .container {
            width: fit-content;
            margin: 0;
        }
    }
}