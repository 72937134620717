.win {
	$gap: ac(182px, 80px);
	margin-top: $gap;
}

.win--careers {
	$gap: ac(146px, 80px);
	margin-top: $gap;
}

.win {
	&__wrapper {
		display: flex;
	}

	&--alt {
		.win__col {
			padding-left: 0;
			margin-right: 0;
			margin-left: ac(118px, 35px, 1024, 1440);
		}
	}

	&__col {
		padding-left: ac(1px, 0px, 1024, 1440);
		flex: 0 0 auto;
		width: 100%;
		max-width: ac(603px, 470px, 1024, 1440);
		margin-right: ac(118px, 35px, 1024, 1440);
		z-index: 1;
	}

	&__title,
	&__descr {
		letter-spacing: 0.1px;
	}

	&__title {
		padding-left: ac(2px, 0px, 1024, 1440);
	}

	&__descr {
		margin-top: ac(40px, 30px);
		font-size: ac(30px, 25px);
		line-height: ac(36px, 30px);
		font-weight: 600;
	}

	&__btn {
		margin-top: 29px;
	}

	&__video-box {
		position: relative;
		margin-top: 13px;
	}

	&__decor {
		position: absolute;
		z-index: -1;

		img {
			object-fit: contain;
		}

		&:nth-child(1) {
			left: -72px;
			top: -104px;
			width: ac(220px, 150px);
			height: ac(220px, 150px);
			background-color: var(--pastel);
			border-radius: 100%;
		}

		&:nth-child(2) {
			height: ac(285px, 200px, 1024, 1440);
			right: ac(-94px, -44px, 1024, 1440);
			top: -62px;
			width: ac(530px, 400px, 1024, 1440);
		}

		&:nth-child(3) {
			bottom: ac(-57px, 10px, 1024, 1440);
			left: 35px;
			width: ac(283px, 244px, 1024, 1440);
			height: ac(325px, 280px, 1024, 1440);
		}
	}

	&--careers {
		.win__decor:nth-child(3) {
			bottom: ac(-65px, 10px, 1024, 1440);
		}
	}

	@mixin tab {
		&--alt {
			.win__wrapper {
				flex-direction: column-reverse;
			}

			.win__col {
				margin-left: 0;
			}

			.win__video-box {
				margin-top: ac(80px, 35px);
				max-width: 650px;
			}
		}

		&__wrapper {
			flex-direction: column;
			align-items: center;
		}

		&__col {
			max-width: unset;
			margin-right: 0;
		}

		&__video-box {
			margin-top: 113px;
			max-width: 650px;
		}

		&__decor {
			&:nth-child(1) {
				width: ac(220px, 150px, 375, 1024);
				height: ac(220px, 150px, 375, 1024);
				left: ac(-72px, -39px, 375, 1024);
				top: ac(-104px, -71px, 375, 1024);
			}

			&:nth-child(2) {
				right: ac(-110px, -54px, 375, 1024);
				top: ac(-40px, -29px, 375, 1024);
				width: ac(540px, 310px, 375, 1024);
				height: ac(300px, 166px, 375, 1024);
			}

			&:nth-child(3) {
				bottom: ac(-110px, -75px, 375, 1024);
				left: ac(25px, 14px, 375, 1024);
				width: ac(350px, 220px, 375, 1024);
				height: ac(350px, 220px, 375, 1024);
			}
		}

		&--careers {
			.win__decor:nth-child(3) {
				bottom: ac(-110px, -75px, 375, 1024);
			}
		}
	}
}
