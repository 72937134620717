.supply {
	$gap: ac(150px, 100px);
	margin-top: $gap;

	&__btn-list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: ac(30px, 15px);
		margin-bottom: ac(40px, 20px);
	}

	.banner {
		margin-bottom: 15px;

		&__wrapper {
			padding-bottom: 56px;
		}

		&__title {
			margin-bottom: 20px;
		}

		&__bg {
			right: ac(-120px, -100px);
			top: ac(-155px, -39px);

			img {
				transform: rotate(-40deg);
			}

			@mixin media 550 {
				top: initial;
				bottom: 0;
				opacity: 0.5;
			}
		}
	}

	&__wrapper {
		display: flex;
	}

	&__card {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		align-items: flex-start;
		padding: ac(38px, 20px, 1024, 1440) ac(30px, 20px, 1024, 1440) ac(42px, 20px, 1024, 1440);
		border-radius: 7px;
		position: relative;
		background: var(--blue);
		color: var(--white);
		overflow: hidden;
		cursor: pointer;

		.btn {
			margin: auto 0 0 auto;
			border: none;
			position: relative;
			z-index: 5;
			pointer-events: none;
		}

		&:hover {
			.btn {
				background-color: var(--pastel);

				.arrow-btn span {
					color: var(--white);
				}
			}
		}
	}

	&__title {
		color: var(--white);
		font-weight: 700;
		margin-bottom: 18px;
		font-size: ac(35px, 30px, 1024, 1440);
		line-height: ac(42px, 36px, 1024, 1440);
		position: relative;
		z-index: 5;
		word-break: break-word;
	}

	&__descr {
		color: var(--white);
		font-weight: 600;
		font-size: ac(25px, 20px);
		line-height: ac(30px, 24px);
		max-width: 234px;
		margin-bottom: ac(61px, 45px, 1024, 1440);
		position: relative;
		z-index: 5;

		@mixin max-line-length 3;
	}

	&__banner {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: ac(38px, 20px, 1024, 1440) ac(30px, 20px, 1024, 1440);
		border-radius: 7px;
		background-color: var(--pastel);
		overflow: hidden;

		.supply__title {
			margin-top: auto;
			margin-bottom: 0;
		}

		.btn {
			margin-top: ac(61px, 48px, 1024, 1440);
			border-color: var(--white);
		}

		&:hover {
			.btn {
				background-color: var(--pastel);
				border-color: var(--tuna);

				.arrow-btn span {
					color: var(--white);
				}
			}
		}
	}

	&__bg {
		position: absolute;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__slider-box {
		display: flex;
	}

	&__slider {
		width: 100% !important;
		overflow: visible !important;
	}

	.swiper-slide {
		&:first-child {
			.supply__bg {
				top: -65px;
				right: -135px;
				width: 320px;
				height: 287px;

				@mixin media 1081{
					right: -155px;
				}
			}
		}

		&:nth-child(2) {
			.supply__bg {
				right: -52px;
				top: initial;
				bottom: 45px;
				width: 182px;

				img {
					transform: rotate(-40deg);
				}
			}
		}

		&:nth-child(3) {
			.supply__bg {
				width: 282px;
				bottom: 0;
				left: -35px;

				@mixin media 1081{
					bottom: -20px;
				}
			}
		}

		&:nth-child(4) {
			.supply__bg {
				bottom: -145px;
				left: -180px;
				width: 565px;

				img {
					transform: scaleX(-1);
				}
			}
		}
	}

	@media (min-width: 1024px) {
		.swiper-slide {
			max-width: 294px;
			flex-shrink: 1;
		}

		.swiper-wrapper {
			gap: 15px;
		}
		/*&__slider-box {
			flex: 0 0 auto;
			width: 100%;
			max-width: ac(809px, 626px, 1024, 1440);
			margin-right: 15px;
		}

		&__slider {
			.swiper-wrapper {
				width: auto;
				flex-wrap: wrap;
				margin-right: -15px;
				margin-bottom: -16px;
			}

			.swiper-slide {
				display: flex;
				height: auto;
				width: calc(100% / 2 - 15px);
				margin-right: 15px;
				margin-bottom: 16px;
			}
		}*/
	}

	@mixin tab {
		&__wrapper {
			flex-direction: column;
		}

		&__nav {
			margin-top: 30px;
			display: flex;
			justify-content: center;

			.arrow-btn {
				width: 37px;
				height: 37px;
				margin: 0 5px;

				span {
					line-height: 37px;
				}
			}
		}

		&__banner {
			margin-top: 100px;
			padding: 30px;
		}

		&__card {
			padding: 30px;
		}
	}

	@mixin mob-lg {
		&__banner {
			min-height: 483px;
		}

		&__title {
			max-width: 210px;
		}
	}
}
