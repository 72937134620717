.header {
    position: fixed;
    width: 100%;
    transition: 0.3s ease;
    z-index: 50;
    background-color: var(--white);

    &.sticky {
        border-bottom: 1px solid var(--tuna);
    }

    &__wrap,
    &__top .container {
        display: flex;
    }

    &__logo img,
    &__layout {
        position: absolute;
    }

    &__top {
        padding: 13px 0;
        background-color: var(--pastel);

        .header__link {
            font-size: 14px;
            margin-left: 31px;
            letter-spacing: 0;

            &:first-child {
                margin-left: auto;
            }

            &::before {
                display: none;
            }
        }
    }

    &__wrap {
        align-items: center;
        padding: 15px 0;
        transition: 0.3s ease;
    }

    &__logo {
        flex: 0 0 auto;
        position: relative;
        width: 141px;
        height: 50px;
        z-index: 1;

        img {
            object-fit: contain;
            transition: 0.3s ease;

            &:nth-child(2) {
                opacity: 0;
            }
        }

        &:hover {
            img {
                &:nth-child(1) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }
    }

    &__navbar-box {
        margin-left: auto;
    }

    &__search {
        position: relative;
        display: inline-flex;
        align-items: center;
        top: 2px;
        width: 16px;
        height: 16px;
        z-index: 50;
        transition: .3s ease;

        input {
            position: absolute;
            left: -15px;
            width: 0;
            opacity: 0;
            padding: 0 40px 0 15px;
            @mixin transparent-bg-input var(--tuna);
            border: 1px solid var(--tuna);
            border-radius: 7px;
            outline: none;
            transition: .3s ease;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &,&::placeholder {
                font-family: var(--font-main);
                font-weight: 600;
                font-size: ac(16px, 14px, 1024, 1440);
                line-height: ac(46px, 38px, 1279, 1280);
                color: var(--tuna);
            }

            &::placeholder {
                opacity: .5;
            }

            &:focus {
                border-color: var(--blue);
            }

            &::-webkit-search-decoration {
                -webkit-appearance: none;
            }

            &:focus, &:valid {
                opacity: 1;
                width: ac(330px, 200px, 320, 568);

                &+button {
                    transform: translateX(285px);
                }
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 16px;
            height: 16px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%232B3245' fill-rule='evenodd' d='M7 1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM.25 7a6.75 6.75 0 1 1 12.05 4.18l3.26 3.26-1.06 1.06-3.253-3.253A6.75 6.75 0 0 1 .25 7Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            transition: .3s ease;

            &:hover {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%236284e0' fill-rule='evenodd' d='M7 1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM.25 7a6.75 6.75 0 1 1 12.05 4.18l3.26 3.26-1.06 1.06-3.253-3.253A6.75 6.75 0 0 1 .25 7Z' clip-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }

    &__link {
        position: relative;
        margin-left: ac(25px, 19px, 1024, 1440);
        font-size: ac(16px, 14px, 1024, 1440);
        line-height: 1em;
        font-weight: 600;
        letter-spacing: 0.1px;
        cursor: pointer;

        &:first-child {
            margin-left: auto;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -7px;
            width: 0;
            height: 2px;
            background-color: var(--tuna);
            transition: 0.3s ease;
        }

        &.active,
        &:hover {
            color: var(--blue);

            &::before {
                width: 100%;
                left: unset;
                right: 0;
            }
        }
    }

    &__layout {
        width: 100%;
        height: 100vh;
        bottom: -1px;
        transform: translateY(100%);
        background-color: var(--white);
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease;
        z-index: -1;
        cursor: pointer;
    }

    &__icon {
        flex: 0 0 auto;
        width: 40px;
        height: 37px;
        margin-right: 58px;

        img {
            object-fit: contain;
        }
    }

    .js-dropdown {
        position: relative;
        z-index: 2;
        display: inline-block;
        margin-left: ac(25px, 19px, 1024, 1440);

        &.open {
            &>.header__link {
                color: var(--blue);

                &::before {
                    width: 100%;
                    left: unset;
                    right: 0;
                }
            }
        }
    }

    &__dropdown {
        width: calc(100% + 140px);
        position: absolute;
        left: 50%;
        bottom: -15px;
        transform: translate(-50%, 100%);
        transform-origin: 0 0;
        border: 2px solid var(--tuna);
        overflow: hidden;
        visibility: hidden;
        background-color: var(--white);
        transition: .5s ease-in-out;
        max-height: 0px;

        .simplebar-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &.open {
            max-height: 200px;
            opacity: 1;
            visibility: visible;
            overflow: hidden;
        }

        &>div {
            padding: 15px;
            max-height: 200px;
            overflow: auto;
            width: 100%;

            .simplebar-track.simplebar-vertical {
                width: 6px;
            }

            .simplebar-scrollbar {

                &::before {
                    opacity: 1;
                    background: var(--tuna);
                }
            }
        }

        .header__link {
            margin-left: 0;

            &:before {
                display: none;
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }

    @media (min-width: 1280px) {
        &.sticky {
            margin-top: -40px;

            .header__wrap {
                padding: 10px 0;
            }

            &:hover {
                margin-top: 0;
            }
        }

        .header__dropdown {
            .simplebar-content-wrapper, .simplebar-mask {
                overflow: auto !important;
            }

            .simplebar-track {
                display: block !important;
            }
        }
    }

    @media (max-width: 1279px) {
        padding: 30px 0;

        &.sticky {
            padding: 10px 0;
        }

        &.active {
            .header__navbar-box {
                transform: translateX(0%);
                opacity: 1;
                visibility: visible;
            }

            .header__layout {
                opacity: 0.3;
                visibility: visible;
            }

            .header__burger {
                span {
                    &:nth-child(1) {
                        width: 0;
                        opacity: 0;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        width: 38px;
                        transform: rotate(-45deg) translate(7px, -7px);
                    }
                }
            }
        }

        .header__simplebar {
            max-height: 100%;
            padding-right: 20px;
            width: calc(100% + 20px);
        }

        .simplebar-track {
            width: 10px;
            border-radius: 20px;
            cursor: pointer;
        }

        .simplebar-content-wrapper {
            padding: 0 ac(30px, 15px) 0 0;
        }

        .simplebar-scrollbar::before {
            background-color: var(--pastel);
            opacity: 1;
        }

        &__search {
            top: 0;
            width: 100%;
            height: 40px;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                opacity: 1;

                &:focus, &:valid {
                    width: 100%;

                    &+button {
                        transform: translateX(0);
                    }
                }
            }

            button {
                right: 10px;
                top: 11px;
            }
        }

        &__wrap {
            padding: 0;
        }

        &__burger {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            margin-left: auto;
            width: 46px;
            height: 24px;
            cursor: pointer;
            z-index: 1;

            span {
                width: 100%;
                height: 4px;
                background-color: var(--blue);
                transition: 0.3s ease;

                &:nth-child(1) {
                    width: 29px;
                }

                &:nth-child(2) {
                    width: 38px;
                }
            }

            &:hover {
                span {
                    background-color: var(--tuna);
                }
            }
        }

        &__navbar-box {
            width: 100%;
            max-width: 400px;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            position: absolute;
            right: 0;
            top: 0;
            padding: 137px 38px 27px;
            background-color: var(--alice);
            transform: translateX(100%);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s ease;
        }

        &__navbar {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__link {
            font-size: 28px;
            line-height: 34px;
            margin-left: 0;
            letter-spacing: 0.3px;

            &:first-child {
                margin-left: 0;
            }

            &:not(:first-child) {
                margin-top: 20px;
            }

            &::before {
                display: none;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 2px;
            margin-top: 87px;

            .header__link {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0;

                &:not(:first-child) {
                    margin-top: 15px;
                }
            }
        }

        .js-dropdown {
            margin-top: 20px;
            margin-left: 0;
        }

        &__dropdown {
            position: relative;
            transform: none;
            width: 100%;
            left: 0;
            bottom: 2px;
            border: 0;
            background-color: var(--alice);

            .header__link {
                margin-top: 0 !important;
                font-size: 24px;
            }
        }
    }

    @mixin mob-xl {
        padding: 20px 0;

        &__navbar-box {
            max-width: unset;
        }
    }

    @mixin mob-sm {
        &__navbar-box {
            padding: 137px 15px 27px;
        }
    }
}
