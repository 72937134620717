.care-first {
    position: relative;
    padding: 155px 0 ac(150px, 80px);

    &__heading {
        padding: 60px ac(103px, 40px) 60px 0;
        max-width: ac(910px, 640px, 768, 1440);

        &::before {

            background: var(--blue);
        }
    }

    &__title {
        color: var(--tuna);
        margin-bottom: 40px;
    }

    &__buttons {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        .btn {
            margin-top: 0 !important;
        }
    }

    &__images {
        position: absolute;
        height: vw(488);
        width: vw(397);

        max-width: 397px;
        max-height: 488px;

        pointer-events: none;

        min-height: 345px;
        min-width: 282px;

        top: 0;
        left: 91%;
    }

    &__img {
        position: absolute;

        &:first-child {
            width: vw(309);
            height: vw(309);

            max-width: 309px;
            max-height: 309px;

            min-width: 180px;
            min-height: 180px;

            border-radius: 50%;
            overflow: hidden;
            bottom: -40px;
            left: 0;
            z-index: 5;
        }

        &:nth-child(2) {
            width: vw(315);
            height: vw(315);
            max-width: 315px;
            max-height: 315px;

            min-width: 210px;
            min-height: 180px;

            overflow: hidden;
            top: 40px;
            right: 0;

            img {
                clip-path: url(#rect);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--org {
        .care-first__images {
            min-height: auto;
        }

        .care-first__img {
            &:first-child {
                bottom: auto;
                top: 12px;
            }

            &:nth-child(2) {
                width: vw(380);
                height: vw(315);
                max-width: 380px;
                max-height: 315px;
                top: auto;
                bottom: -32px;
                z-index: 10;

                img {
                    clip-path: url(#rect-2);
                }
            }
        }

        .care-first__heading:before {
            background: var(--pastel);
        }
    }

    @mixin tab {
        padding-top: ac(190px, 140px, 320, 1024);

        &__title {
            max-width: 75%;
        }

        &__images {
            top: -80px;
            right: -35px;
            left: auto;
        }
    }

    @mixin tab-sm {
       &__heading {
           padding-top: 120px;
           padding-bottom: 150px;
       }

        &__images {
            height: 100%;
            top: 0;
            max-height: 100%;
        }

        &__title {
            max-width: 400px;
        }

        &__img {
            &:nth-child(2) {
                top: -35px;
            }

            &:first-child {
                left: auto;
                right: 0;
                bottom: -35px;
            }
        }

        &--org {
            .care-first__img {
                &:first-child {
                    top: -60px;
                }

                &:nth-child(2) {
                    bottom: -30px;
                }
            }

            .care-first__images {
                max-height: 100%;
            }
        }
    }
}

