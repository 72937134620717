.reviews {
	$gap: ac(151px, 100px);
	margin-top: $gap;
}

.reviews--dark {
	$gap: 0;
	margin-top: $gap;

	.reviews__circle span {
		transform: translateY(-50%)!important;
	}

	.reviews__text-box .swiper-slide {
		padding-top: ac(50px, 0px, 1024, 1440);

		.simplebar-content.active {
			&>* {
				padding-right: 0;
			}
		}
	}
}

.reviews--org {
	$gap: ac(168px, 80px);
	margin-top: $gap;

	.reviews__circle span {
		transform: translateY(-50%)!important;
	}

	.reviews__text-box .swiper-slide {
		padding-top: ac(50px, 0px, 1024, 1440);

		.simplebar-content.active {
			&>* {
				padding-right: 0;
			}
		}
	}
}

.reviews {
	position: relative;

	&__circle {
		position: absolute;
		left: ac(-530px, -370px, 1024, 1440);
		top: calc(50% + 36px);
		z-index: -1;

		span {
			position: absolute;
			transform: translateY(-43%) !important;
			width: ac(670px, 450px, 1024, 1440);
			height: ac(670px, 450px, 1024, 1440);
			border: 70px solid var(--blue);
			border-radius: 100%;
		}
	}

	&__bg {
		height: ac(570px, 300px, 1024, 1440);
		width: ac(570px, 300px, 1024, 1440);
		position: absolute;
		right: ac(-259px, -145px, 1024, 1440);
		top: calc(50% + 52px);
		transform: translateY(-50%) !important;
		z-index: -1;

		img {
			object-fit: contain;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		padding: 0 ac(104px, 50px, 1024, 1440);
	}

	&__title {
		margin-right: ac(324px, 20px);
	}

	&__rate {
		margin-top: ac(31px, 23px);
		width: ac(191px, 108px);
		height: ac(29px, 16px);

		img {
			object-fit: contain;
		}
	}

	&__slider {
		margin-top: 30px;
		width: 100%;
		display: flex;
		align-items: flex-start;
	}

	.reviews__thumb,
	.reviews__text {
		width: 100% !important;
		height: 100%;
	}

	.reviews__text{
		position: relative;
		top: -24px;

		@mixin media 1025{
			top: 0;
		}

	}

	&__thumb-box,
	&__text-box {
		height: 314px;
	}

	&__thumb-box {
		width: 100%;
		max-width: 354px;
		flex: 0 0 auto;
		margin-right: ac(58px, 20px, 1024, 1440);

		.swiper-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease;

			&:hover,
			&-thumb-active {
				opacity: 1;
				cursor: pointer;
			}
		}
	}

	&__text-box {
		width: 100%;
		max-width: ac(500px, 422px, 1024, 1440);

		.swiper-slide {
			display: flex;
			flex-direction: column;
			justify-content: center;

			& > span {
				&:first-child {
					height: 52px;
					font-size: 62px;
					font-family: 'Arial', sans-serif;
					margin-bottom: -12px;
					margin-left: 2px;

					display: flex;
					align-items: center;

					flex: 0 0 auto;
					width: 45px;

					img {
						object-fit: contain;
					}
				}
			}
		}
	}

	&__nav {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		margin-right: ac(55px, 20px, 1024, 1440);

		.arrow-btn {
			margin: 10px 0;

			span {

			}
		}
	}

	&__card {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 390px;
	}

	&__ava {
		flex: 0 0 auto;
		width: 88px;
		height: 88px;
		margin-right: 15px;
		border-radius: 44px 0 0;
		overflow: hidden;
	}

	&__name {
		font-weight: 600;
		font-size: ac(20px, 16px);
		line-height: ac(24px, 19px);
	}

	&__company {
		margin-top: 7px;
		font-size: ac(16px, 14px);
		line-height: ac(22px, 20px);
	}

	&__descr {
		font-size: ac(20px, 16px);
		line-height: ac(26px, 22px);

		& > div {
			max-height: 222px;
		}

		p {
			line-height: inherit;
		}

		.simplebar-content {
			padding-right: 1rem !important;
			& > * {
				&:not(:last-child) {
					margin-bottom: ac(30px, 21px);
				}
			}
		}

		.simplebar-scrollbar::before {
			width: 5px;
			background-color: var(--pastel);
			opacity: 1;
		}

		.simplebar-vertical {
			width: 9px;
		}
	}

	&--dark {
		color: var(--white);
		background-color: var(--tuna);
		padding: ac(75px, 40px) 0 ac(83px, 45px);

		.reviews__bg, .reviews__circle {
			z-index: 1;
			pointer-events: none;
		}

		.arrow-btn {
			background: var(--alice);

			span {
				color: var(--tuna);
			}

			&:hover {
				background: var(--pastel);
			}
		}
	}

	@mixin tab {
		&__rate {
			margin-left: auto;
		}

		&__slider {
			flex-direction: column;
		}

		&__thumb-box {
			max-width: unset;
		}

		&__thumb-box,
		&__text-box,
		&__nav {
			height: auto;
		}

		&__text-box {
			max-width: unset;
		}

		&--dark {
			.reviews__nav {
				background: var(--tuna);
			}
		}

		&__nav {
			flex-direction: row;
			margin: 30px 0 -26px auto;
			z-index: 2;
			background-color: var(--white);

			.arrow-btn {
				width: 37px;
				height: 37px;
				margin: 0 0 0 10px;

				span {
					line-height: 37px;
				}
			}
		}

		&__ava {
			width: ac(88px, 60px, 375, 1024);
			height: ac(88px, 60px, 375, 1024);
			margin-right: 20px;
		}
	}

	@mixin tab-sm {
		&__wrapper {
			padding: 0;
		}

		&__circle,
		&__bg {
			display: none;
		}
	}
}
