.first {
    $gap: 0;
    padding-top: ac(155px, 120px, 1366, 1440);
    margin-top: $gap;

    .container {
        height: 100%;
    }

    &--home {
        padding-bottom: 41px;
        border-bottom: 1px solid var(--pastel);

        .container {
            display: flex;
        }
    }

    &--resources {
        padding-top: ac(215px, 150px);
        padding-bottom: 0;
        border-bottom: 0;
        height: auto;

        .first {
            &__grid {
                margin-top: ac(97px, 35px);

                &>h2 {
                    white-space: nowrap;
                }
            }

            &__wrapper {
                display: flex;
                flex-direction: column;
            }

            &__title {
                margin-top: 21px;

                span {
                    color: var(--blue);
                }
            }

            &__submit {
                right: 1px;
                top: -7px;
            }

            &__form {
                margin-top: ac(87px, 35px);
                border: 0;
                max-width: 1015px;
                margin-left: 0;
                padding: 0;
            }

            &__select-box {
                display: flex;
                font-weight: 700;
                font-size: ac(30px, 24px);
                line-height: ac(36px, 30px);

                span {
                    flex: 0 0 auto;
                }

                .choices {
                    margin-left: 9px;
                    margin-right: 9px;

                    &:nth-of-type(1) {
                        min-width: ac(174px, 134px, 320, 1440);
                    }

                    &:nth-of-type(2) {
                        min-width: ac(281px, 174px, 650, 1440);
                    }

                    &__inner {
                        padding-left: 0;
                    }

                    .choices__list--single {
                        .choices__item {
                            font-weight: 700;
                            font-size: ac(30px, 24px);
                            line-height: ac(36px, 30px);
                            padding: 0;
                            border-bottom: 2px solid var(--blue);
                            max-width: 300px;
                            max-height: ac(40px, 32px);
                            @mixin max-line-length 1;
                        }

                        .choices__placeholder {
                            color: rgba(43, 50, 69, 0.5);
                        }
                    }

                    .choices__list--dropdown {
                        .choices__item {
                            padding: 5px 10px;
                            font-weight: 400;
                            font-size: ac(20px, 16px, 1024, 1440);
                            line-height: 1.2;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &--contact {
        padding-top: ac(216px, 146px);
        padding-bottom: 40px !important;
        height: auto !important;
        border-bottom: none;

        .first {
            &__wrapper {
                justify-content: space-between;
            }

            &__suptitle {
                display: inline-block;
                margin-bottom: 20px;
            }

            &__title {
                margin-bottom: 40px;

                span {
                    color: var(--blue);
                }
            }

            &__caption {
                max-width: 603px;
            }

            &__motto {
                p {
                    font-size: ac(20px, 18px);
                    line-height: 130%;
                    letter-spacing: 0.1px;

                    &:first-of-type {
                        font-size: ac(25px, 20px);
                        line-height: normal;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
                }
            }

            &__contact-info {
                padding: ac(62px, 42px) 20px ac(46px, 35px) ac(103px, 20px);
                position: relative;
                display: flex;
                flex-direction: column;
                font-size: ac(30px, 20px);
                font-weight: bold;
                margin-top: 15px;
                width: ac(500px, 320px);

                li {
                    margin-bottom: 19px;
                    position: relative;
                    z-index: 1;

                    &:nth-of-type(2) {
                        margin-left: 9px;
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    width: 300%;
                    height: 100%;
                    position: absolute;
                    left: ac(0px, -7px);
                    top: 0;
                    z-index: 0;
                    background: var(--alice);
                    border-bottom-left-radius: ac(150px, 100px);
                }
            }
        }
    }

    &--positions {
        padding-top: ac(215px, 150px);
        padding-bottom: 0;
        border-bottom: 0;

        .first {
            &__wrapper {
                position: relative;
                flex-direction: column;
            }

            &__form {
                &--hidden {
                    .choices {
                        min-width: ac(313px, 215px, 768, 1440);
                    }
                }
            }

            &__decor {
                position: absolute;
                right: ac(-14px, 0px, 320, 1440);
                top: ac(-124px, -70px, 320, 1440);
                width: ac(293px, 120px, 992, 1440);
                height: ac(256px, 100px, 320, 1440);
                z-index: -1;

                img {
                    object-fit: contain;
                }
            }

            &__title {
                margin-top: 21px;
            }

            &__form {
                margin-top: 41px;
                margin-bottom: 14px;

                &--hidden {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &__tags {
                margin-top: 23px;
            }
        }
    }

    &--details {
        padding-top: ac(215px, 150px);

        .first {
            &__wrapper {
                flex-direction: column;

                .btn {
                    margin-top: ac(50px, 25px);
                }
            }

            &__title {
                margin-top: ac(20px, 15px);
            }

            &__info-box {
                margin-top: ac(41px, 30px);
                display: flex;
                flex-wrap: wrap;
                margin-right: ac(-160px, -20px);
                margin-bottom: -15px;
            }

            &__info {
                display: flex;
                flex-direction: column;
                margin-right: ac(160px, 20px);
                margin-bottom: 15px;

                span {
                    &:nth-child(1) {
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--blue);
                        font-weight: 500;
                    }

                    &:nth-child(2) {
                        margin-top: 5px;
                        font-weight: 600;
                        font-size: ac(25px, 18px);
                        line-height: ac(30px, 24px);
                    }
                }
            }
        }
    }

    &--article {
        padding-top: ac(216px, 170px);

        .first {
            &__title {
                position: relative;
                color: var(--blue);
                z-index: 1;

                &::before {
                    content: "";
                    position: absolute;
                    top: ac(-61px, -40px);
                    right: ac(-104px, -10px, 768, 1440);
                    width: calc(100% + 50vw);
                    height: calc(100% + ac(120px, 80px));
                    background-color: var(--alice);
                    border-radius: 0px 7px ac(150px, 80px) 0px;
                    z-index: -1;
                }
            }

            &__wrapper {
                margin-top: 90px;
                display: flex;
            }

            &__col {
                flex: 0 0 auto;
                width: 100%;
                max-width: ac(500px, 420px, 1024, 1440);
                margin-right: 118px;
                min-height: unset;
            }

            &__descr {
                margin-top: ac(60px, 40px);
                font-weight: 600;
                font-size: ac(30px, 22px);
                line-height: ac(36px, 30px);
            }

            &__info {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-right: ac(-38px, -15px);
                margin-bottom: -10px;

                span {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    margin-right: ac(38px, 15px);
                    margin-bottom: 10px;

                    &:first-child {
                        padding: 6px 11px;
                        border-radius: 7px;
                        background-color: var(--pastel);
                    }
                }
            }

            &__img-box {
                position: relative;
                margin-top: -131px;
                width: ac(712px, 580px, 1024, 1440);
                height: ac(452px, 380px, 1024, 1440);
                flex: 0 0 auto;
            }

            &__img {
                border-radius: 0;
                width: 100%;
                height: 100%;

                img {
                    border-radius: 226px 0 0 0;
                }
            }
        }
    }

    &--blogs,
    &--category {
        padding-top: ac(215px, 150px);

        .first {
            &__title {
                color: var(--blue);
            }

            &__wrapper {
                margin-top: ac(41px, 25px);
                display: flex;
                position: relative;
            }

            &__col {
                flex: 0 0 auto;
                width: 100%;
                max-width: ac(603px, 420px, 1024, 1440);
                margin-right: 15px;
                min-height: unset;
            }

            &__descr {
                font-weight: 600;
                font-size: ac(30px, 22px);
                line-height: ac(36px, 30px);
            }

            &__icon {
                width: 195px;
                height: ac(178px, 140px);
                margin-top: ac(60px, 15px, 1024, 1440);
                margin-left: 4px;

                img {
                    object-fit: contain;
                }
            }

            &__bottom {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                max-width: 397px;
                margin-left: auto;
                text-align: right;
                margin-top: -54px;
            }

            &__link {
                margin-top: ac(18px, 10px);
                display: flex;
                align-items: flex-end;

                .icon-arrow {
                    font-size: 21px;
                }

                & > span {
                    &:nth-child(1) {
                        margin-right: 19px;
                        font-weight: 700;
                        font-size: ac(25px, 18px);
                        line-height: ac(30px, 26px);
                    }
                }

                .arrow-btn {
                    margin-bottom: 5px;
                }
            }

            &__img-box {
                position: relative;
                margin-top: auto;
                margin-bottom: 5px;
                width: ac(712px, 580px, 1024, 1440);
                height: ac(452px, 380px, 1024, 1440);
                flex: 0 0 auto;
            }

            &__img {
                border-radius: 0;
                width: 100%;
                height: 100%;

                img {
                    border-radius: 226px 0 0 0;
                }
            }

            &__decor {
                position: absolute;
                right: -18px;
                top: -160px;
                width: ac(568px, 460px, 1024, 1440);
                height: ac(680px, 580px, 1024, 1440);

                img {
                    object-fit: contain;
                }
            }
        }
    }

    &--blogs {
        .first {
            &__title {
                font-size: ac(80px, 40px);
                line-height: ac(96px, 50px);
            }

            &__bottom {
                margin-top: ac(180px, 100px, 1024, 1440);
            }

            &__link {
                .icon-arrow {
                    font-size: 21px;
                }
            }
        }
    }

    &--careers {
        padding-top: ac(215px, 150px);
        padding-bottom: 44px;
        border-bottom: 1px solid var(--pastel);

        .first {
            &__wrapper {
                position: relative;

                .first__btn {
                    position: absolute;
                    right: ac(98px, -90px);
                    top: ac(-8px, -2px);
                    align-items: center;
                    z-index: 1;

                    .arrow-btn {
                        width: ac(37px, 29px);
                        height: ac(37px, 29px);
                        margin-right: ac(21px, 10px);

                        span {
                            font-size: ac(20px, 14px);
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: ac(-29px, -20px);
                        left: ac(-104px, -15px);
                        width: calc(100% + 50vw);
                        height: calc(100% + ac(60px, 40px));
                        border-radius: ac(54px, 30px) 0 0 ac(54px, 30px);
                        background-color: var(--tuna);
                        z-index: -1;
                    }

                    span:not(.arrow-btn) {
                        font-weight: 700;
                        font-size: ac(25px, 16px);
                        line-height: ac(30px, 20px);
                        transition: 0.3s ease;

                        color: var(--white);
                    }
                }

                & > .first__btn {
                    .arrow-btn {
                        width: ac(48px, 29px);
                        height: ac(48px, 29px);
                    }
                }
            }

            &__col {
                max-width: ac(707px, 550px, 1024, 1440);
                align-items: flex-start;
            }

            &__title {
                margin-top: ac(21px, 40px);

                span {
                    color: var(--blue);
                }
            }

            &__descr {
                margin-top: ac(40px, 25px);
                max-width: 499px;

                p {
                    &:first-child {
                        line-height: 26px;
                    }

                    &:not(:first-child) {
                        margin-top: ac(20px, 15px);
                        font-size: ac(16px, 14px);
                        line-height: 22px;
                    }
                }
            }

            &__img-box {
                position: relative;
                margin-top: 121px;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
            }

            &__award {
                position: absolute;
                bottom: ac(45px, 60px, 1024, 1440);
                left: ac(-112px, -40px, 1024, 1440);
                height: ac(240px, 140px, 1024, 1440);
                width: ac(154px, 95px, 1024, 1440);
                z-index: 1;

                img {
                    object-fit: contain;
                }
            }

            &__img-row {
                display: flex;
                gap: 15px;

                &:first-child {
                    margin-left: 103px;
                }
            }

            &__img {
                position: relative;
                width: ac(191px, 150px, 1024, 1440);
                height: ac(191px, 150px, 1024, 1440);
                border-radius: 5px;
                overflow: hidden;
            }

            &__btn-box {
                margin-top: ac(75px, 35px);
                display: flex;
                flex-wrap: wrap;
                gap: 25px;
                margin-right: -25px;
            }

            &__btn {
                display: flex;
                align-items: flex-end;
                min-width: 284px;
                margin-right: 0;
                background-color: transparent;

                &:hover {
                    .arrow-btn {
                        background-color: var(--blue);
                    }

                    span:not(.arrow-btn) {
                        color: var(--blue);
                    }
                }

                .arrow-btn {
                    justify-content: center;
                    align-items: center;
                    background-color: var(--pastel);
                    margin-right: 21px;

                    .icon-arrow {
                        transform: rotate(90deg);
                    }
                }

                span:not(.arrow-btn) {
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 30px;
                    margin-bottom: -4px;
                    transition: 0.3s ease;
                }
            }
        }
    }

    &__scroll-down {
        position: absolute;
        bottom: ac(0px, -50px, 1024, 1440);
        left: 0;
        z-index: 2;
    }

    &__keys {
        margin-top: ac(50px, 35px);
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
    }


    &__key {
        margin-right: 15px;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: 10px 15px;
        background: rgba(170, 188, 238, 0.5);
        border-radius: 7px;
        transition: background 0.3s ease;

        &:hover {
            background: rgba(170, 188, 238, 1);
        }

        display: flex;
        align-items: center;
        gap: 4px;

        &--delete {
            font-size: 12px;
            transition: 0.3s ease;

            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    &__grid-head {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--tuna);
        background-color: var(--alice);
        border-radius: 7px;
        font-weight: 600;
        font-size: ac(25px, 18px);
        line-height: ac(30px, 22px);
        padding: ac(30px, 20px, 1024, 1440) ac(30px, 20px, 651, 1440) ac(22px, 20px, 1024, 1440);
        transition: 0.3s ease;
        cursor: pointer;

        p {
            margin-right: 30px;
        }

        .first__btn {
            margin-right: 0;
            width: 29px;
            height: 29px;
            margin-bottom: auto;

            span {
                font-size: 15px;
                line-height: 29px;
                transform: rotate(90deg);
            }
        }

        &:hover,
        &.open {
            background-color: var(--tuna) !important;
            color: var(--white) !important;
        }
    }

    &__grid-content {
        max-height: 0;
        overflow: hidden;

        background-color: var(--alice);
        transition: 0.5s ease;
        opacity: 0;

        div {
            border-radius: 7px;
            padding: 30px;
            border: 1px solid var(--tuna);
        }

        &.open {
            opacity: 1;
            max-height: 2000px;
        }
    }

    &__suptitle {
        position: relative;
        color: var(--blue);
        font-weight: 700;
        letter-spacing: 0.3px;

        &::before {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--blue);
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }

    &__col {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: 100%;
        max-width: ac(809px, 550px, 1024, 1440);
        padding-bottom: ac(239px, 189px, 1024, 1440);
        margin-right: 15px;
    }

    &__title-box {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding-top: ac(60px, 30px, 1024, 1440);
        padding-right: ac(80px, 40px, 1024, 1440);
        padding-bottom: ac(74px, 30px, 1024, 1440);

        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% + 50vw);
            height: 100%;
            background-color: var(--alice);
            border-radius: 0 7px ac(150px, 70px) 0;
            z-index: -1;
        }
    }

    &__title {
        & > span {
            position: relative;

            & > span {
                position: absolute;
                bottom: ac(-36px, -30px);
                left: 0;
                width: 100%;
                height: ac(50px, 35px);
                background: url("../images/title-decor.svg") center / contain no-repeat;
                z-index: -1;
            }
        }
    }

    &__subtitle {
        margin-top: ac(74px, 27px, 1024, 1440);
        font-size: ac(30px, 25px);
        line-height: ac(35px, 30px);
        font-weight: 600;
        letter-spacing: 0.1px;
    }

    &__submit {
        position: absolute;
        right: 6px;
        top: 6px;
        width: 48px;
        height: 48px;

        &.static {
            position: static;
        }

        span {
            font-size: 21px;
            line-height: 48px;
        }
    }

    &__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: ac(13px, 20px) ac(-16px, -21px) -11px 2px;

        span {
            opacity: 0.5;
        }

        span,
        a {
            font-size: ac(16px, 14px);
            margin-right: ac(16px, 21px);
            margin-bottom: 11px;
        }

        a {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: ac(1px, -2px);
                width: 100%;
                height: 2px;
                background-color: var(--blue);
            }
        }
    }

    &__img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc(100% + 8vw);
        height: ac(224px, 174px, 1024, 1440);
        overflow: hidden;

        img {
            border-radius: 0 10vw 10vw 0;;
        }
    }

    &__card-box {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__card {
        position: relative;
        display: flex;
        align-items: flex-end;
        padding: ac(25px, 21px);
        height: ac(224px, 192px);
        overflow: hidden;
        border-radius: ac(30px, 7px) 112px 112px ac(30px, 7px);
        cursor: pointer;

        &:not(:first-child) {
            margin-top: 15px;
        }

        &:nth-child(2) {
            border-radius: 112px ac(30px, 7px);
        }

        &:nth-child(3) {
            border-radius: 112px 112px ac(30px, 7px) ac(30px, 7px);
        }

        &:hover {
            .first__btn {
                background-color: var(--white);
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    &__card-title {
        max-height: ac(168px, 136px);
        font-size: ac(35px, 28px);
        line-height: ac(42px, 34px);
        font-weight: 700;
        color: var(--white);
        margin-bottom: ac(3px, 0px);
        letter-spacing: 0.3px;
        @mixin max-line-length 4;
    }

    &__btn {
        margin-right: ac(30px, 12px);
        background-color: var(--pastel);

        span {
            font-size: ac(21px, 17px);
            color: var(--tuna);
        }
    }

    &--calculator {
        height: auto !important;
        padding-top: ac(215px, 150px) !important;
        padding-bottom: ac(60px, 35px);
        border: none;

        .first {
            &__suptitle {
                display: inline-block;
                margin-bottom: 21px;
            }

            &__title {
                margin-bottom: 40px;
                letter-spacing: 0.1px;
            }

            &__motto {
                font-size: ac(30px, 22px);
                font-weight: 600;
                line-height: 1.2;
                max-width: 809px;
                letter-spacing: 0.1px;
            }

            &__wrapper {
                align-items: flex-start;
            }

            &__thumbnail {
                margin-top: ac(-61px, 10px);
                margin-left: ac(108px, 15px);
                flex-shrink: 0;
            }
        }
    }

    @media (min-width: 770px) {
        &__grid {
            display: grid;
            gap: 15px;
            grid-template-areas:
				"item item item"
				"desc desc desc";
            grid-template-columns: 1fr 1fr 1fr;
        }

        &__grid-content {
            grid-area: desc;
        }
    }

    @media (min-width: 1024px) {
        &--home {
            height: calc(100vh - 300px);

            .first__col {
                min-height: 630px;
            }
        }

        &__col {
            height: 100%;
            padding-bottom: 0;
        }

        &__title-box {
            justify-content: center;
            height: calc(calc(100% / 3 - 3px) * 2);
            padding-right: ac(80px, 40px, 1024, 1440);
            padding-bottom: 10px;
            padding-top: 0;
        }

        &__subtitle {
            margin-top: ac(74px, 10px, 1366, 1440);
        }

        &__form {
            margin-top: ac(26px, 10px, 1366, 1440);
        }

        &__img {
            height: calc(100% / 3 - 10px);
        }

        &__card {
            height: calc(100% / 3 - 10px);

            &:not(:first-child) {
                margin-top: 0px;
            }
        }
    }

    @media (min-width: 1441px) {
        &__img {
            width: calc(100% + 15vw);
        }
    }

    @media (min-width: 1920px) {
        &__img {
            width: calc(100% + 30vw);
        }
    }

    @media (max-height: 1080px) {
        &--home {
            height: 100vh;
        }
    }

    @media (max-height: 790px) {
        &--home {
            height: auto;
        }
    }

    @mixin tab {
        &--home {
            height: auto !important;
            padding-bottom: 80px;
        }

        &--careers {
            .first__descr,
            .first__col {
                max-width: 100%;
            }

            .first__img-box {
                margin: 40px auto 0;
            }

            .first__img {
                display: block;
            }
        }

        &--resources {
            padding-bottom: 0;

            .first__select-box {
                flex-wrap: wrap;

                .choices .choices__list--single .choices__item {
                    max-width: unset;
                }
            }

            .first__form {
                padding-right: ac(100px, 60px, 650, 1024);
            }
        }

        &--article {
            .first__col {
                max-width: 100%;
                margin-right: 0;
            }

            .first__img-box {
                margin: ac(60px, 30px) auto 0;
                width: 100%;
                max-width: 800px;
                height: ac(400px, 190px, 320, 1024);
            }

            .first__img {
                display: block;

                img {
                    border-radius: 80px 80px 0 0;
                }
            }
        }

        &--blogs,
        &--category {
            .first__col {
                max-width: 100%;
                margin-right: 0;
            }

            .first__bottom {
                margin-top: -156px;
            }

            .first__img-box {
                margin: 60px auto 0;
                width: 100%;
                max-width: 800px;
                height: ac(400px, 190px, 320, 1024);
            }

            .first__decor {
                height: ac(500px, 250px, 320, 1024);
                right: ac(165px, 44px, 320, 1024);
                top: ac(-54px, -36px, 320, 1024);
                width: ac(460px, 220px, 320, 1024);
            }

            .first__img {
                display: block;

                img {
                    border-radius: 80px 80px 0 0;
                }
            }
        }

        &--blogs {
            .first__bottom {
                margin-top: 30px;
            }
        }

        &__wrapper {
            flex-direction: column;
        }

        &--calculator {
            padding-top: ac(150px, 103px, 320, 1024) !important;

            .first__wrapper {
                flex-direction: column-reverse;
            }

            .first__thumbnail {
                margin-left: auto;
                margin-right: 0;
                margin-bottom: ac(-40px, -30px, 375, 1023);
                z-index: -1;
                max-width: 68%;
            }
        }

        &__col {
            width: calc(100% + 21px);
            max-width: unset;
            margin-right: 0;
            padding-bottom: 0;
        }

        &__title-box {
            padding-top: 41px;
            padding-right: 20px;
            padding-bottom: 53px;
        }

        &__subtitle {
            margin-top: ac(60px, 30px, 375, 1024);
        }

        &__img {
            display: none;
        }

        &__card-box {
            flex-direction: row;
            justify-content: space-between;
            width: calc(100% + 42px);
            margin-left: -21px;
            margin-top: 19px;
        }

        &__card {
            width: calc(100% / 3 - 10px);

            &:not(:first-child) {
                margin-top: 0px;
            }
        }

        &--contact {
            .first__caption {
                margin-bottom: ac(50px, 15px, 375, 1023);
            }
        }
    }

    @mixin tab-md {
        &__card-box {
            flex-direction: column;
            align-items: center;
        }

        &__card {
            width: 100%;
            max-width: 441px;

            &:not(:first-child) {
                margin-top: 19px;
            }
        }
    }

    @mixin tab-sm {
        &__grid-head {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        &__grid-content {
            & > div {
                margin-top: 5px;
            }
        }

        &--resources {
            .first__select-box {
                .choices {
                    margin-left: 9px;
                    margin-right: 9px;

                    &:nth-of-type(1) {
                        min-width: ac(174px, 134px, 320, 1440);
                    }

                    &:nth-of-type(2) {
                        margin: 5px 0 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    @mixin mob-xl {
        &--blogs,
        &--category {
            .first__bottom {
                margin-top: 15px;
            }
        }
    }

    @mixin mob-lg {
        &--careers {
            .first__img-row:first-child {
                margin-left: ac(103px, 50px, 320, 550);
            }

            .first__img {
                width: ac(150px, 100px, 320, 550);
                height: ac(150px, 100px, 320, 550);
            }

            .first__award {
                left: ac(-40px, -27px, 320, 550);
                height: ac(140px, 105px, 320, 550);
                width: ac(95px, 70px, 320, 550);
            }
        }

        &__form {
            padding: 0 80px 0 20px;
            margin-top: 20px;
            margin-left: 0;
            flex-direction: column;

            input:not(.choices__input) {
                border-right: 0;
                border-bottom: 1px solid var(--tuna);
            }

            .choices__inner,
            input:not(.choices__input) {
                padding-left: 0;
            }

            .choices__item--selectable,
            input:not(.choices__input) {
                line-height: 52px;
                max-width: 100%;
            }
        }

        &__form--hidden {
            padding-right: 20px;
        }

        &__submit {
            right: 10px;
            top: 28px;
        }

        &__tags {
            margin-left: 0;
        }
    }
}
