.positions.positions--careers {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.positions {
    $gap: 15px;
    margin-top: $gap;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-right: -15px;
    }


    &__card {
        display: flex;
        flex-direction: column;
        padding: 9px ac(30px, 15px) ac(39px, 15px);
        width: calc(100% / 3 - 15px);
        border-radius: 7px;
        border: 1px solid var(--tuna);
        min-height: ac(336px, 230px);
        transition-duration: .3s !important;
        transition-property: background-color, color, opacity, transform !important;
        background: var(--white);

        &:hover {
            background-color: var(--tuna);
            color: var(--white);

            .positions__btn {
                background-color: var(--white);

                .icon-arrow {
                    color: var(--tuna)
                }
            }
        }
    }

    &__btn {
        margin-left: auto;
        margin-right: ac(-22px, -6px);
        width: 29px;
        height: 29px;

        .icon-arrow {
            line-height: 29px;
            font-size: 13px;
        }
    }

    &__title {
        margin-bottom: auto;
        font-weight: 700;
        font-size: ac(35px, 24px);
        line-height: ac(42px, 32px);
        letter-spacing: .2px;
    }

    &__text {
        margin-top: 10px;
        font-weight: 600;
        font-size: ac(25px, 18px);
        line-height: ac(30px, 24px);
    }

    &--careers {
        padding-bottom: 150px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: ac(400px, 300px);
            background-color: var(--tuna);
            z-index: -1;
        }

        .positions {
            &__form {
                position: relative;
                margin-top: ac(40px, 20px);
                z-index: 20;
                max-width: ac(960px, 800px, 1024, 1440);

                .first__accardion-head {
                    transform: translateY(0);
                    margin-top: 15px;

                    &.open {
                        margin-top: 0;
                    }
                }
            }

            &__subtitle {
                margin-top: ac(40px, 20px);
                font-weight: 600;
                font-size: ac(25px, 20px);
                line-height: ac(30px, 25px);
            }

            &__slider-box {
                position: relative;
                display: flex;
                margin-top: ac(56px, 30px);
                z-index: 10;
            }

            &__slider {
                width: 100% !important;
                overflow: visible !important;
            }

            &__card {
                width: 100%;
                height: 100%;

                &:hover {
                    border-color: var(--white);
                }
            }

            &__nav {
                position: absolute;
                right: 0;
                top: ac(-94px, -65px, 1024, 1440);

                .positions__prev {
                    margin-right: 15px;
                }
            }
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }

        &--careers {
            .positions__nav {
                top: unset;
                right: unset;
                left: 50%;
                transform: translateX(-50%);
                bottom: -75px;

                .positions__prev {
                    margin-right: 10px;
                }

                .arrow-btn {
                    background-color: var(--white);
                    border: 1px solid var(--tuna);


                    span {
                        color: var(--tuna);
                    }

                    &:hover {
                        background-color: var(--tuna);
                        border: 1px solid var(--white);

                        span {
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}
