.banner {
	$gap: ac(16px, 20px);
	margin-top: $gap;

	&__wrapper {
		position: relative;
		padding: ac(42px, 30px) 30px ac(50px, 40px);
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		background-color: var(--tuna);
		border-radius: 7px;
		overflow: hidden;
	}

	&__bg {
		position: absolute;
		right: ac(-31px, -65px);
		top: ac(-142px, -39px);
		width: ac(430px, 224px);
		height: ac(382px, 210px);

		img {
			object-fit: contain;
		}
	}

	&__subtitle,
	&__title {
		position: relative;
		color: var(--white);
		z-index: 1;
	}

	&__title {
		width: 100%;
		margin-bottom: 31px;
		letter-spacing: 0.4px;
	}

	&__subtitle {
		margin-right: auto;
		width: calc(100% - 250px);
		max-width: 834px;
		font-weight: 600;
		font-size: ac(25px, 20px);
		line-height: ac(30px, 24px);
		letter-spacing: 0.1px;
	}

	&__btn {
		z-index: 1;
		border-color: var(--white);

		&:hover {
			border-color: var(--tuna);
		}
	}

	&--about {
		.banner__title {
			margin-bottom: 20px;
		}

		.banner__wrapper {
			padding-bottom: 58px;
		}

		.banner__bg {
			height: 100%;
			top: 0;
			width: 32.5vw;
			min-width: 245px;
			max-width: 468px;

			@mixin media 415 {
				min-width: 180px;
			}
		}

		.btn {
			margin-bottom: -16px;
		}

		.banner__title, .banner__subtitle {
			max-width: 780px;
		}
	}

	@mixin mob-xl {
		&__subtitle {
			width: 100%;
		}

		&__btn {
			margin-top: 39px;
		}

		&--about {
			.banner__bg {
				top: auto;
				bottom: -36%;
				right: -20px;
			}

			.btn {
				margin-bottom: 0;
			}
		}
	}
}
