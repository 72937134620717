:root {
	--font-main: "Figtree", sans-serif;
	--tuna: #2b3245;
	--white: #ffffff;
	--alice: #f3f6ff;
	--blue: #6284e0;
	--pastel: #aabcee;
	--red: #D40059;
	--bg-color: 43 50 69;
	--bg-color-opacity: 43 50 69 / 0;

	--none: 0;
	--main: ac(150px, 100px);
	--sm: ac(45px, 25px);
}

.fadeIn{
	opacity: 0;
	animation: fade-in 0.5s forwards;
}

@keyframes fade-in {
	0%{
		opacity: 0;
	}
	
	100%{
		opacity: 1;
	}
}