.care-search {
    position: relative;
    $gap: 0;
    margin-top: $gap;
    background: var(--alice);
    padding: 80px 0 75px;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: ac(325px, 180px);
        background-color: var(--tuna);
    }

    .first__form {
        max-width: 100%;
    }

    &__caption {
        margin-bottom: 50px;
    }

    &__form {
        max-width: 810px;

        .first__wrap {
            margin-top: 4px;
        }

        .first__form--hidden {
            margin-top: 14px;
        }

        .first__form input {
            max-width: ac(403px, 245px, 1024, 1440);
        }

        .first__tags {
            margin-left: 0px;
            max-width: calc(100% - ac(230px, 0px, 769, 770));
        }
    }

    &__wrap {
        max-width: ac(912px, 800px, 1024, 1440);
        margin-left: ac(207px, 0px, 1024, 1440);
        margin-bottom: 56px;
        position: relative;
        z-index: 5;
    }

    &__title {
        font-weight: 700;
        font-size: ac(35px, 30px);
        line-height: ac(42px, 38px);
        max-width: 243px;
        flex: 0 0 auto;
    }

    &__desc {
        max-width: 603px;
        p {
            font-weight: 500;
            font-size: ac(20px, 18px);
            line-height: 130%;
        }
    }

    .positions-nav {
        display: flex;
        gap: 20px;
        position: absolute;
        right: 0;
        bottom: calc(100% + 54px);
    }

    .positions-slider {
        width: 100% !important;
        overflow: visible !important; 
    }

    .positions__card {
        width: 100%;
    }

    .first__accardion {
        margin-top: 0;
    }

    .swiper-wrapper {
        &>h2 {
            height: ac(336px, 234px);
            width: 100%;
            text-align: center;
        }
    }

    @mixin tab {
        .positions-nav {
            bottom: -60px;
            width: 100%;
            justify-content: center;

            .arrow-btn {
                background-color: var(--white);
                border: 1px solid var(--tuna);
                
                span {
                    color: var(--tuna);
                }

                &:hover {
                    background-color: var(--tuna);
                    border-color: var(--white);

                    span {
                        color: var(--white);
                    }
                }
            }
        }
    }


    @mixin tab-md {
        &__title, &__desc {
            max-width: 100%;
        }

        &__caption {
            flex-direction: column;
        }
    }

    @mixin tab-sm {
        .first__accardion {
            margin-top: 20px;
        }

        .first__wrap {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }

    @mixin mob-lg {
        .positions-slider {
            max-width: 100%;
        }
    }
}