html {
    font-size: ac(20px, 16px);
    line-height: ac(24px, 19px);
    font-family: var(--font-main);
    font-weight: 500;
    color: var(--tuna);

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--tuna);
    }

    scrollbar-width: thin;
    scrollbar-color: transparent var(--tuna);
}

body {
    min-width: 320px;
    margin: 0;
    background-color: var(--white);

    &.no-scroll {
        overflow: hidden;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.swiper-wrapper {
    left: 0 !important;
}

.swiper-button-disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

.plyr__video-wrapper{
    z-index: 0;
}

#site {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

a {
    word-break: break-all;
}

div, span, p, h1, h2, h3, h4, h5, h6 {
    word-break: break-word;
}

.main {
    flex-grow: 1;
}

.container {
    width: 100%;
    max-width: 1298px;
    margin: 0 auto;
    padding: 0 38px;

    @mixin media 550 {
        padding: 0 20px;
    }
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.simplebar-content {
    &.active {
        & > * {
            padding-right: 15px;
        }
    }
}

.choices {
    flex-grow: 1;
    margin-bottom: 0;

    &::after {
        display: none;
    }

    .choices__list {
        width: 100%;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;

        &--dropdown {
            z-index: 2;

            .choices__item, .choices__input {
                line-height: 30px;
            }

            .choices__item {
                &::after {
                    display: none;
                }
            }
        }

        & > div {
            padding-right: 20px;
            width: calc(100% + 20px);
            max-height: calc((ac(20px, 16px) * 1.2 + 10px) * 5);
        }

        .simplebar-track.simplebar-vertical {
            right: 20px;
        }

        .simplebar-scrollbar::before {
            width: 5px;
            background-color: var(--pastel);
            opacity: 1;
        }

        .simplebar-vertical {
            width: 9px;
        }
    }

    .choices__inner,
    .choices__list--single {
        padding: 0;
        padding-bottom: 0 !important;
    }

    .choices__inner {
        background-color: var(--white);
        border: 0;
        border-radius: 0;
    }

    .choices__item--selectable {
        font-size: 16px;
        font-weight: 300;
        color: var(--tuna);
        line-height: 48px;
        opacity: 1;
    }
}

input {
    &,
    &::placeholder {
        font-size: 16px;
        font-weight: 300;
        color: var(--tuna);
    }

    border-radius: 0;
}

.arrow-btn {
    flex: 0 0 auto;
    display: inline-flex;
    justify-content: center;
    width: ac(48px, 37px);
    height: ac(48px, 37px);
    border-radius: 7px;
    background-color: var(--tuna);
    transition: 0.3s ease;

    .icon-arrow-left {
        margin-right: 2px;
    }
    .icon-arrow-right {
        margin-left: 2px;
    }

    span {
        color: var(--white);
        line-height: ac(48px, 37px);
        font-size: ac(28px, 22px);
        transition: 0.3s ease;
    }

    &:hover {
        background-color: var(--blue);
    }

    &.arrow-btn-white {
        background: var(--white);
        color: var(--tuna);

        span {
            color: inherit;
        }

        &:hover {
            background: var(--blue);
            color: var(--white);
        }
    }
}

.btn {
    display: inline-flex;
    padding: 4px 5px 4px 9px;
    font-size: 16px;
    line-height: 29px;
    background: var(--white);
    border: 1px solid #2b3245;
    border-radius: 7px;
    transition: 0.3s ease;
    cursor: pointer;

    & > span {
        &:first-child {
            margin-right: 11px;
            color: var(--tuna);
        }
    }

    .arrow-btn {
        width: 29px;
        height: 29px;
        background-color: var(--blue);

        span {
            font-size: 15px;
            line-height: 29px;
            color: var(--tuna);
        }
    }

    &:hover {
        background-color: var(--pastel);

        .arrow-btn {
            span {
                color: var(--white);
            }
        }
    }
}

.plyr {
    &__poster {
        background-size: cover;
    }

    &__control--overlaid {
        padding: 0;
        width: ac(88px, 53px) !important;
        height: ac(54px, 32px);
        border-radius: 10px;
        background-color: var(--blue);
        opacity: 1;

        &::before {
            content: "\e90c";
            font-family: "icomoon" !important;
            color: var(--white);
            font-size: ac(26px, 15px);
            line-height: ac(54px, 32px);
            margin-left: ac(8px, 6px);
        }

        &:hover {
            background-color: var(--tuna) !important;
        }

        svg {
            display: none;
        }
    }
}

.plyr--video.plyr--stopped .plyr__controls {
    display: none;
}

.fade-in {
    animation: fade-in 1s ease;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.pagination {
    margin-top: ac(60px, 35px);
    display: flex;
    justify-content: center;
    gap: 6px;

    &__num {
        display: flex;
        justify-content: center;
        width: 33px;
        height: 33px;
        padding-top: 1px;
        font-weight: 500;
        font-size: 20px;
        line-height: 33px;
        background-color: var(--alice);
        transition: .3s ease;
        cursor: pointer;
        user-select: none;

        border-radius: 7px;

        &:not(:last-child) {
            margin-right: 1px;
        }

        &:hover, &.active {
            color: var(--white);
            background-color: var(--tuna);
        }
    }
}


/* Decor items */
.circle {
    position: absolute;
    z-index: -1;

    span {
        position: absolute;
        transform: translateY(-50%);
        width: ac(670px, 450px, 1024, 1440);
        height: ac(670px, 450px, 1024, 1440);
        border: 70px solid var(--blue);
        border-radius: 100%;
    }
}

.circle-fill {
    width: ac(186px, 150px, 1025, 1440);
    height: ac(186px, 150px, 1025, 1440);
    background: var(--tuna);
    border-radius: 50%;
}

.clip-elements {
    position: absolute;
    z-index: -999;
}

#bold-credits {
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

@mixin mob-lg {
    .choices {
        .choices__item--selectable {
            font-size: 14px;
        }
    }

    input {
        &,
        &::placeholder {
            font-size: 14px;
        }
    }
}

@mixin mob-sm {
    .container {
        padding: 0 15px;
    }
}

/* Common sections */
.intro-section {
    &__wrap {
        display: flex;
        align-items: center;
    }

    &__inner {
        max-width: 608px;
    }

    &__title {
        max-width: 500px;
        margin-bottom: ac(40px, 20px);
    }

    &__subtitle {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: ac(25px, 22px);
        line-height: 120%;
    }

    .btn {
        margin-top: 40px;
    }

    .container {
        width: fit-content;
        flex: 1;
        max-width: 775px;
    }

    &__img {
        max-width: 507px;
        width: 100%;
        flex: 1;
    }

    &--about {
        .intro-section__img {
            max-width: 507px;
            width: 100%;
            @mixin aspect-ratio 507,492;
        }

        .intro-section__subtitle {
            letter-spacing: .1px;
        }

        .content-element {
            p {
                line-height: 22px;
                letter-spacing: 0.04px;

                &:not(:last-child) {
                    margin-bottom: 9px;
                }
            }
        }
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
        }

        .intro-section__img {
            margin-bottom: 40px;
        }
    }

    @mixin min-media 1536 {
        &__img {
            margin-left: auto;
        }

        .container {
            margin-left: 40px;
        }
    }
}

.intro-section--about {
    $gap: ac(172px, 80px);
    padding-top: $gap;
}

.first-heading {
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100% + 50vw);
        height: 100%;
        background-color: var(--alice);
        border-radius: 0 7px ac(150px, 70px) 0;
        z-index: -1;
    }

    &--rounded {
        &::before {
            border-radius: 0 ac(218px, 100px) ac(218px, 100px) 0;
        }
    }
}


.first-subtitle {
    font-weight: 700;
    font-size: 20px;
    display: inline-block;
    margin-bottom: 15px;
    color: var(--blue);
    padding-bottom: 6px;
    border-bottom: 2px solid var(--blue);

    &--light {
        color: var(--alice);
        border-color: var(--alice);
    }
}

/* Accordion */
.first {
    &__accardion {
    }

    &__form {
        position: relative;
        display: flex;
        margin-top: 26px;
        margin-left: 2px;
        padding: 6px 62px 6px 7px;
        width: 100%;
        max-width: 705px;
        background-color: var(--white);
        border-radius: 7px;
        border: 1px solid var(--pastel);

        .choices__inner {
            padding-left: 30px;
        }

        input:not(.choices__input) {
            width: 100%;
            max-width: ac(370px, 245px, 1024, 1440);
            padding-left: 23px;
            padding-right: 30px;
            border-right: 1px solid var(--tuna);

            &,
            &::placeholder {
                line-height: 48px;
            }

            @mixin transparent-bg-input var(--tuna);
        }

        &--hidden {
            margin-top: 0;
            margin-bottom: 10px;
            max-width: 959px;
            padding: 6px 7px;

            .choices {
                &:only-child {
                    width: 247px;
                    max-width: 100%;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: ac(32px, 5px);
                    top: ac(19px, 20px);
                    width: 8px;
                    height: 8px;
                    border-right: 2px solid var(--tuna);
                    border-bottom: 2px solid var(--tuna);
                    transform: rotate(45deg);
                    transition: .3s ease;
                }

                &:not(:first-child) {
                    .choices__inner {
                        padding-left: ac(29px, 15px);
                    }
                }

                &:nth-child(2) {
                    min-width: ac(320px, 225px, 768, 1440);
                }

                &:not(:last-child) {
                    border-right: 1px solid var(--tuna);
                    min-width: 225px;
                }

                &__inner {
                    padding-left: ac(22px, 15px);
                }

                &.is-open {
                    &::before {
                        transform: rotate(-130deg);
                        top: ac(22px, 28px);
                    }
                }
            }

            @mixin tab-sm {
                flex-direction: column;
                padding: 0 20px;

                .choices {
                    .choices__inner {
                        padding-left: 0;
                    }

                    &:not(:first-child) {
                        .choices__inner {
                            padding-left: 0;
                        }
                    }

                    &:nth-child(2) {
                        min-width: ac(320px, 225px, 768, 1440);
                    }

                    &:not(:last-child) {
                        border-right: 0;
                        border-bottom: 1px solid var(--tuna);
                    }
                }
            }
        }
    }

    &__accardion-head {
        position: relative;
        display: inline-flex;
        padding-right: 30px;
        padding-left: 2px;
        width: 162px;
        transition: .3s ease;
        cursor: pointer;
        transform: translateY(-4px);

        span {
            &:first-child, &:nth-child(2) {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: -6px;
                    background-color: var(--tuna);
                    transition: .3s ease;
                }
            }

            &:nth-child(2) {
                position: absolute;
                opacity: 0;
                width: 178px;
            }

            &.icon-arrow {
                position: absolute;
                color: var(--blue);
                top: 4px;
                right: 6px;
                font-size: 14px;
                transform: rotate(90deg);
                transition: .3s ease;
            }
        }

        &:hover {
            span {
                &:first-child, &:nth-child(2) {
                    color: var(--blue);

                    &::before {
                        background-color: var(--blue);
                    }
                }
            }
        }

        &.open {
            width: 210px;
            transform: translateY(0);
            margin-bottom: 5px;

            span {
                &:first-child {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                    transition: .3s ease;
                }

                &.icon-arrow {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__accardion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        transition: 0.5s ease;

        &.open {
            opacity: 1;
            overflow: visible;
            max-height: 1000px;
            margin-bottom: 13px;
        }
    }
}

/* About us */
.story {
    position: relative;
    margin-top: -36px;

    &__heading {
        max-width: 810px;
    }

    &__subtitle {
        font-size: ac(30px, 25px);
        font-weight: 600;
        line-height: 122%;
        margin: ac(40px, 20px) 0 18px;
    }

    &__desc {
        font-weight: 600;
        font-size: ac(25px, 22px);
    }

    .circle--story {
        right: 0;
        top: 25%;

        span {
            transform: translate(-22%, -50%);
        }
    }

    @mixin tab {
        margin-top: ac(45px, 25px, 320, 1024);

        &__heading {
            max-width: 86%;
        }
    }

    @mixin media 460 {
        .circle--story {
            display: none;
        }

        &__heading {
            max-width: 100%;
        }
    }
}

.teammate-video {
    $gap: ac(150px, 80px);
    margin-top: $gap;
    padding: 80px 0 ac(115px, 80px);
    color: var(--white);
    background: var(--tuna);

    &__content {
        max-width: 810px;
        margin: 0 auto;
    }

    &__title {
        margin-bottom: 48px;
    }

    &__decor {
        position: absolute;

        &:first-of-type {
            max-width: 507px;
            width: 100%;
            bottom: -11%;
            left: -9%;

            img {
                transform: rotate(-160deg);
            }
        }

        &:nth-of-type(2) {
            width: 220px;
            height: 220px;
            border-radius: 50%;
            bottom: -9%;
            right: 21%;
            background: var(--pastel);
        }

        &:nth-of-type(3) {
            max-width: 282px;
            width: 100%;
            right: -10%;
            bottom: 11%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @mixin tab-md {
            &:first-of-type {
                width: 56.27vw;
            }

            &:nth-of-type(2) {
                width: 24.4vw;
                height: 24.4vw;
            }

            &:nth-of-type(3) {
                width: 31.30vw;
            }

        }
    }
}

.submit-btn {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    flex-shrink: 0;

    span {
        padding-right: 20px;
        transition: color 0.3s;
    }

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 7px;
        background: var(--tuna);
        color: var(--white);
        font-size: ac(22px, 18px);
        transition: 0.3s;
    }

    &:hover {
        color: var(--blue) !important;

        i {
            color: var(--white) !important;
            background: var(--blue) !important;
        }
    }
    &.submit-white{
        color: var(--white);
        .icon-arrow{
            background: var(--white);
            color: var(--tuna);
        }
    }
}

.input-wr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--hidden {
        opacity: 0;
        transform-origin: 0 0;
        transform: scale(0);
        transition: .3s ease;
        max-height: 0;

        &.active {
            max-height: 100px;
            transform: scale(1);
            opacity: 1;
        }
    }

    &--alt {
        .choices {
            width: 100%;
            margin: 0 !important;

            &::before {
                content: '';
                position: absolute;
                right: ac(20px, 15px);
                top: ac(25px, 22px);
                width: 10px;
                height: 10px;
                border-right: 2px solid var(--tuna);
                border-bottom: 2px solid var(--tuna);
                transform: rotate(45deg);
                transition: .3s ease;
            }

            &.is-open {
                &::before {
                    transform: rotate(-135deg);
                }
            }

            .choices__input--cloned {
                border: 0;
                border-bottom: 1px solid var(--blue);
                border-radius: 0;
            }

            &__inner {
                padding: 0 ac(48px, 30px) 0 ac(30px, 15px);
                border: 1px solid var(--blue);
                border-radius: 7px;
                display: flex;
                min-height: ac(62px, 57px);
                align-items: center;

            }

            &__list--single {
                .choices__item {
                    color: var(--tuna) !important;
                    font-weight: 300;
                    font-size: 16px;
                    border: 0;
                }

                .choices__placeholder {
                    color: rgba(43, 50, 69, 0.5);
                }
            }
        }
    }

    span {
        padding-bottom: 12px;
        font-size: 16px;
        font-weight: 500;
        b{
            color: var(--red);
        }
    }

    input, textarea {
        width: 100%;
        padding: 18px ac(30px, 15px);
        border: 1px solid var(--blue);
        border-radius: 7px;
        color: var(--tuna);
        font-weight: 300;
        font-size: 16px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: inherit !important;
        }

        &::placeholder {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
            font-family: inherit;
        }
    }
    textarea{
        resize: none;
        height: 124px;
    }
}

.single-select-wr {
    display: flex;
    flex-direction: column;

    & > span {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 12px;
        b{
            color: var(--red);
        }
    }

    .choices {
        width: 100%;


        &__inner {
            padding: 0 ac(48px, 20px) 0 ac(30px, 15px);
            border: 1px solid var(--blue);
            border-radius: 7px;
            display: flex;
            min-height: 62px;
            align-items: center;

        }

        .choices__item--selectable {
            line-height: normal;
        }

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;

            @mixin media 650 {
                right: 14px;
            }
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }
    }

    .choices__list {
        & > div {
            width: 100%;
            padding-right: 0;
        }

        &--dropdown {
            .choices__item--selectable {
                line-height: 40px;
            }
        }
    }
}

.single-range {
    padding-right: 10px;
    margin-bottom: 10px;
    height: 2px;
    border-radius: 12px;
    border: none;
    box-shadow: none;
    background: var(--blue);

    &-container {
        width: 100%;
        padding-top: 11px;
    }


    .noUi-connect {
        background: var(--blue);
    }


    .noUi-handle {
        background: var(--blue);
        width: 10px!important;
        height: 10px;
        cursor: pointer;
        border-radius: 2px;
        box-shadow: none;
        top: -4px;
        right: -10px!important;
        border: none;


        &:before,
        &:after {
            content: none;
        }
    }
}

.single-range-info {
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 0;
}


.contact-form {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px 15px;

        .btn-wr {
            display: flex;
        }
    }

    &-caption {
        display: flex;
        align-items: center;
        font-size: ac(30px, 25px);
        margin-bottom: 49px;

        h2 {
            margin-right: 12px;
            line-height: normal;
            font-size: inherit;
        }

        .choices {
            margin-top: 2px;
            flex-grow: 1;
            max-width: 303px;

            .choices__inner {
                min-height: unset;
            }

            .choices__list--dropdown {
                .choices__list {
                    &>div {
                        max-height: 220px;
                    }
                }

                .choices__item {
                    &:first-child {
                        display: none;
                    }
                }

            }
        }

        @mixin mob-lg {
            align-items: flex-start;
            flex-direction: column;

            .choices {
                max-width: 100%;
                width: 100%;
                margin: 10px 0  0!important;
            }
        }
    }

    textarea {
        height: 124px;
        resize: none;
    }

    .submit-btn {
        margin-top: 3px;
    }

    @media (max-width: 1023px) {
        .contact-form-grid {
            grid-template-columns: 1fr;
        }
    }
}

.select-wr {

    .choices {
        border-bottom: 2px solid var(--blue);

        .choices__inner {
            min-height: auto;
        }

        .choices__list > div {
            height: auto;
            line-height: normal;
        }

        .choices__item--selectable {
            font-size: ac(30px, 25px);
            font-weight: bold;
            color: var(--tuna);
            opacity: 1;
            opacity: 0.5;
        }
        .choices__list>div{
            width: 100%;
            padding-right: 0;
        }

    }

    .choices[data-type*=select-one] .choices__input {
        display: none;
    }
}

.prioritise {
    $gap: 0;
    margin-top: $gap;
    padding-bottom: 70px;

    &__title {
        max-width: 910px;
        margin-bottom: 60px;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;

        &__slider {
            width: 100% !important;
            overflow: visible !important;
        }

        &__item {
            width: 100%;
            max-width: 180px;

            &-icon {
                font-size: 38px;
                color: var(--blue);
                width: 88px;
                padding-bottom: 10px;
                border-bottom: 1px solid var(--pastel);
            }

            &-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 124%;
                margin-top: 9px;
            }
        }
    }
}

.questions {
    position: relative;
    $gap: ac(150px, 80px);
    padding-top: $gap;

    &__title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__accordions {
        max-width: 810px;
        margin: 0 auto 50px;
        display: flex;
        flex-direction: column;
    }

    .btn {
        display: block;
        width: fit-content;
        margin: 0 auto;
    }

    .reviews__circle {
        top: calc(50% + 84px);

        &:nth-of-type(2) {
            left: auto;
            right: ac(150px, 80px, 1024, 1440);
        }
    }
}

.contact-video {
    $gap: 0;
    margin-top: $gap;
    padding: ac(66px, 45px) 0 ac(152px, 90px);
    background-color: var(--tuna);
    .container{
        position: relative;
    }

    &__wrap {
        max-width: 1015px;
        margin: 0 auto;
        position: relative;
        gap: 15px;
        justify-content: space-between;
        .decors{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__title, &__subtitle {
        line-height: 130%;
    }

    &__title {
        font-size: ac(35px, 30px);
        margin-bottom: 18px;
    }

    &__subtitle {
        font-size: ac(25px, 22px);
        margin-bottom: 17px;
    }

    &__box {
        max-width: 603px;
        position: relative;
        z-index: 5;
    }

    .contacts__link {
        font-size: 20px;
        font-weight: 600;
    }

    &__content {
        background: var(--white);
        border-radius: 7px;
        padding: 40px 30px 36px;
        position: relative;
        z-index: 5;
        max-width: 397px;
        width: 100%;
    }

    &__decor {
        position: absolute;
        z-index: 1;

        &.circle-fill {
            size: vw(220);
            max-width: 220px;
            max-height: 220px;

            min-width: 135px;
            min-height: 135px;

            &:first-of-type {
                top: 16px;
                left: -30px;
                background: var(--pastel);
            }

            &:nth-of-type(2) {
                right: 24.7%;
                top: -74px;
                background: var(--blue);
            }
        }

        &:nth-of-type(3) {
            bottom: -26px;
            height: 15.625vw;
            left: 15.5%;
            max-height: 225px;
            max-width: 389px;
            min-height: 135px;
            min-width: 135px;
            width: 26.888889vw;

            img {
                transform: rotate(12deg);
            }
        }

        &:nth-of-type(4) {
            size: vw(270);

            max-width: 270px;
            max-height: 270px;

            min-width: 135px;
            min-height: 135px;

            right: -65px;
            bottom: -78px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .btn {
        margin-top: 27px;
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column-reverse;
        }

        &__content {
            width: 100%;
        }

        &__box {
            max-width: 100%;
        }

        &__decor {
            &:nth-of-type(3) {
                left: -10%;
                bottom: -90px;
            }

            &:nth-of-type(4) {
                bottom: 40%;
            }
        }
    }
}

/* Care professional end */

/* Care organization start */
.partnership {
    $gap: 0;
    margin-top: $gap;

    &__heading {
        max-width: 603px;
    }

    &__desc {

        margin-top: 40px;

        &, &>* {
            font-weight: 600;
            font-size: ac(25px, 22px);
            line-height: 120%;
        }
    }

    &__wrap {
        gap: 20px;
    }

    &__link {
        margin-top: ac(6px, 0px, 1024, 1440);
        display: flex;
        align-items: center;
        padding: 30px 0 30px 103px;
        position: relative;
        gap: 20px;
        flex-shrink: 0;
        cursor: pointer;
        width: 100%;
        max-width: 500px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            background: var(--tuna);
            width: calc(100% + 50vw);
            border-radius: 54px 0 0 54px;
            z-index: -1;
            transition: 0.3s ease;
        }

        i {
            transform: rotate(-45deg);
            font-size: 24px;
        }

        .arrow-btn {
            background: var(--pastel);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h3 {
            font-size: ac(25px, 22px);
            color: var(--white);
            transition: color 0.3s ease;
        }

        &:hover {
            h3 {
                color: var(--tuna);
            }

            .arrow-btn {
                background: var(--tuna);
            }

            &:before {
                background: var(--pastel);
            }
        }
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column-reverse;
            gap: 40px;
        }

        &__link {
            padding-left: 0;
            padding-right: 40px;
            justify-content: center;

            &:before {
                border-radius: 0 54px 54px 0;
                left: auto;
                right: 0;
            }
        }
    }
}

.videos {
    $gap: 0;
    margin-top: $gap;
    padding-bottom: 30px;
    padding-top: ac(51px, 40px);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 112px);
        background-color: var(--tuna);
    }

    &__wrap {
        display: flex;
        gap: 15px;
        position: relative;
        max-width: 1015px;
        margin: 0 auto;
    }

    &__item {
        width: 100%;
        position: relative;
        z-index: 5;
    }

    &__title {
        color: var(--white);
        margin-bottom: 30px;
    }

    .video-player {
        max-width: 500px;
    }

    &__decor {
        position: absolute;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &:first-of-type, &:nth-of-type(3) {
            width: vw(354);
            height: vw(191);

            max-width: 354px;
            max-height: 191px;

            min-width: 135px;
            min-height: 135px;
        }

        &:first-of-type {
            bottom: -24px;
            left: -8.6%;

            img {
                transform: rotate(-157deg);
            }
        }

        &:nth-of-type(3) {
            right: 13.8%;
            top: -24px;
        }

        &:nth-of-type(4) {
            size: vw(197);
            height: auto;

            max-width: 212px;
            max-height: 212px;

            min-width: 135px;
            min-height: 135px;

            right: -5px;
            bottom: -47px;
        }

        &.circle-fill {
            background: var(--pastel);
            width: 154px;
            height: 154px;
            left: 43%;
            bottom: -46px;
        }
    }

    @mixin tab-md {
        &__wrap {
            gap: 40px;
            flex-direction: column;
        }
    }
}

.actions {
    $gap: ac(120px, 65px);
    margin-top: $gap;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    &__card {
        padding: 40px 30px;
        background: var(--tuna);
        color: var(--white);
        max-width: 294px;
        border-radius: 7px;

        display: flex;
        flex-direction: column;

        &-title {
            margin-bottom: 20px;
        }

        &-desc {
            margin-bottom: 33px;
            font-weight: 600;
            font-size: 25px;
        }

        .btn {
            margin-top: auto;
            border: none;
            width: fit-content;
        }
    }

    @mixin tab {
        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &__card {
            max-width: 100%;
        }
    }

    @mixin mob-xl {
        &__wrap {
            grid-template-columns: 1fr;
        }
    }
}

/* Care organization end */
.contact__form-section {
    position: relative;
    overflow: visible;
    $gap: 0;
    padding-top: $gap;

    .container {
        display: flex;
    }

    .contact-form {
        flex-grow: 1;
        max-width: 66.2%;
        min-height: 20vw;

        @media (max-width: 650px) {
            max-width: 100%;
            width: 100%;
        }
        .contact-form-caption {
            .choices {
                margin-left: 9px;
                margin-right: 9px;

                &:nth-of-type(1) {
                    min-width: ac(174px, 134px, 320, 1440);
                }

                &:nth-of-type(2) {
                    min-width: ac(281px, 174px, 650, 1440);
                }

                &__inner {
                    padding-left: 0;
                }

                .choices__list--single {
                    .choices__item {
                        font-weight: 700;
                        font-size: ac(30px, 24px);
                        line-height: ac(36px, 30px);
                        padding: 0;
                        border-bottom: 2px solid var(--blue);
                    }

                    .choices__placeholder {
                        color: rgba(43, 50, 69, 0.5);
                    }
                }

                .choices__list--dropdown {
                    .choices__item {
                        padding: 5px 10px;
                        font-weight: 400;
                        font-size: ac(24px, 20px);
                        line-height: ac(32px, 28px);

                        &::after {
                            display: none;
                        }
                    }
                }
            }

        }
    }

    &-thumbnail {
        width: perc(627.4);
        position: absolute;
        right: -11.7%;
        top: -119px;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media (max-width: 1023px) {
            top: 50%;
            transform: translate(0, -50%);
        }
        @media (max-width: 650px) {
            display: none;
        }
    }
}

.contact__offices-section {
    $gap: ac(83px, 53px);
    padding-top: $gap;

    .title {
        margin-bottom: 37px;
        margin-left: ac(4px, 0px);
    }
}

.offices-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 51px;

    li {
        display: flex;
        flex-direction: column;
    }

    h3 {
        font-size: ac(30px, 25px);
        padding-bottom: 1px;
        border-bottom: 2px solid var(--blue);
        display: inline-block;
        align-self: flex-start;
        min-width: 168px;
        margin-bottom: 24px;
        font-weight: 600;
    }

    p {
        font-size: ac(20px, 18px);
        font-weight: 500;
        line-height: 1.3;
        margin-bottom: 8px;

        a {
            font-size: inherit;
            margin-bottom: 0;
        }
    }

    a {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 5px;
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 550px) {
        grid-template-columns: 1fr;

        br {
            display: none;
        }
    }
}

.resources_form_result {
    &>h2 {
        width: max-content;
    }
}

.swiper-buttons {
    width: ac(116px, 96px);
    display: flex;
    justify-content: space-between;

    .swiper-button {
        margin: 0;
        transform: none;
        position: static;
        justify-content: center;
        align-items: center;

        span {
            font-size: ac(25px, 22px);
        }
    }
}

.swiper-button {
    &::after {
        display: none;
    }
}

.terms {
    $gap: ac(186px, 130px);
    margin-top: $gap;

    &__wrapper {
        a {
            color: var(--pastel);

            &:hover {
                color: var(--blue);
            }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(21px, 15px);
            }

            &:not(:first-child) {
                margin-top: ac(39px, 20px);
            }
        }
        h1 {
            margin-bottom: ac(50px, 25px);
        }

        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        h4 {
            font-size: ac(30px, 26px);
            line-height: ac(40px, 36px);
        }

        h5 {
            font-size: ac(28px, 24px);
            line-height: ac(38px, 34px);
        }

        h6 {
            font-size: ac(25px, 22px);
            line-height: ac(30px, 28px);
        }

        p, li {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }

        p, ul, ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul, ol {
            padding-left: 0;
            list-style: none;

            li {
                position: relative;
                padding-left: 24px;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    background-color: var(--tuna);
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-counter;

            li {
                counter-increment: my-counter;

                &::before {
                    content: counter(my-counter) ". ";
                    position: absolute;
                    left: 0px;
                    color: var(--tuna);
                    font-weight: 500;
                }
            }
        }
    }
}

.page404 {
    position: relative;
    background-color: var(--pastel);
    background: linear-gradient(-45deg, var(--blue), var(--pastel), var(--alice));
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%
        }

        50% {
            background-position: 100% 50%
        }

        100% {
            background-position: 0% 50%
        }
    }

    &__wrap {
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        z-index: 2;
    }

    h1 {
        margin-bottom: 0.3em;
        color: var(--white);
    }

    p {
        font-size: ac(22px, 18px);
        line-height: 22px;
        margin-bottom: 2em;
        color: var(--white);
    }
}

.sticky:before,
.sticky:after {
    content: "";
    display: table;
}

.arrow-btn {
    &.swiper-button-lock {
        display: none !important;
    }
}

.slider-nav {
    margin: 25px auto 0;
    user-select: none;

    .arrow-btn {
        &:first-child {
            margin-right: 5px;
        }
    }

    &--white {
        .arrow-btn {
            background-color: var(--white);
            border: 1px solid var(--tuna);

            span {
                color: var(--tuna);
            }

            &:hover {
                background-color: var(--tuna);
                border-color: var(--white);

                span {
                    color: var(--white);
                }
            }
        }
    }
}

.termsfeed-com---palette-dark .cc-cp-foot-save {
    color: var(--tuna) !important;
    background-color: var(--white) !important;

    &:hover {
        background-color: var(--pastel) !important;
        color: var(--tuna) !important;
    }
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox+label {
    color: var(--tuna) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content {
    background: var(--alice) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active=true] {
    background: var(--blue) !important;

    button {
        color: var(--white) !important;
        box-shadow: unset !important;
    }
}

.termsfeed-com---pc-dialog {
    .cc-cp-body-tabs {
        background: var(--white) !important;
    }

    .is-inactive, .cc-pc-head-close {
        color: var(--tuna) !important;
    }

    .cc-pc-head-close {
        &:hover {
            color: var(--white) !important;
        }
    }

    .cc-cp-body-tabs-item {
        background: var(--white) !important;

        button {
            color: var(--tuna) !important;
        }
    }

    .cc-cp-body-tabs-item[active=true] {
        background: var(--blue) !important;

        button {
            color: var(--white) !important;
            box-shadow: unset !important;
        }
    }

    .cc-cp-foot, .cc-pc-head {
        background: var(--pastel) !important;
    }

    .cc-cp-body-content {
        background: var(--alice) !important;
    }

    .cc-cp-body-content-entry-title, .cc-cp-body-content-entry-text, .cc-cp-foot-byline, .cc-pc-head-title-text, .cc-pc-head-title-headline {
        color: var(--tuna) !important;

        a {
            color: var(--tuna) !important;
        }
    }

    .cc-cp-foot-save {
        align-items: center;
        display: inline-flex;
        border: 1px solid var(--tuna);
        background-color: var(--white);
        border-radius: 7px;
        margin-bottom: 0 !important;
        padding: 4px 5px 4px 9px;
        transition: .3s ease;
        color: var(--tuna);
        &::after {
            content: '';
            margin-left: 10px;
            border-radius: 7px;
            width: 29px;
            height: 29px;
            transition: .3s ease;
            background-position: center;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%236284E0' rx='7'/%3E%3Cpath stroke='%232B3245' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
        }

        &:hover {
            background-color: var(--pastel);
            color: var(--tuna);
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%236284E0' rx='7'/%3E%3Cpath stroke='%23fff' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
                transform: unset;
            }
        }
    }

    @mixin mob-lg {
        .cc-cp-foot {
            flex-direction: column;
            padding-bottom: 20px
        }
    }
}

@media only screen and (max-width: 480px) {
    .termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
        justify-content: space-between !important;
        min-width: unset;
    }

    .cc-nb-buttons-container button {
        width: auto !important;
    }

    .termsfeed-com---palette-dark.termsfeed-com---nb .cc-nb-main-container {
        padding: 1.5em 1rem !important;
    }
}

.cc-nb-text, .cc-nb-title, .cc-pc-head-title-text, .cc-pc-head-title-headline, .cc-cp-foot-save, .cc-cp-foot-byline, .cc-cp-body-tabs-item-link, .cc-cp-body-content-entry > *, .cc-custom-checkbox>label {
    font-family: var(--font-main) !important;
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
    background: var(--alice) !important;
    border: 1px solid var(--blue);
    border-radius: 7px 0 0 0;

    .cc-nb-main-container {
        padding: 1.5em 2rem !important;
    }

    .cc-nb-title, .cc-nb-text {
        color: var(--tuna) !important;
    }

    .cc-nb-text {
        margin-bottom: 0;
    }

    .cc-nb-buttons-container {
        button {
            align-items: center;
            display: inline-flex;
            border: 1px solid var(--tuna);
            background-color: var(--white);
            border-radius: 7px;
            margin-bottom: 0 !important;
            margin-top: 13px !important;
            padding: 4px 5px 4px 9px;
            transition: .3s ease;
            color: var(--tuna);

            &::after {
                content: '';
                margin-left: 10px;
                border-radius: 7px;
                width: 29px;
                height: 29px;
                transition: .3s ease;
                background-position: center;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%236284E0' rx='7'/%3E%3Cpath stroke='%232B3245' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
            }

            &:hover {
                background-color: var(--pastel);
                color: var(--tuna);

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%236284E0' rx='7'/%3E%3Cpath stroke='%23fff' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
                    transform: unset;
                }
            }
        }
    }
}

#show_more {
    margin-top: ac(45px, 25px);

    .btn {
        padding: 4px 9px;
    }
}

.grecaptcha-badge {
    z-index: 1000 !important;
    bottom: 90px !important;
}

.snackbar-container  {
    &>button {
        flex: 0 0 auto;
    }
}
