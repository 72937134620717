.counter {
	$gap: ac(115px, 80px);
	padding-top: $gap;

	&__wrapper {
		display: flex;
		max-width: 1015px;
		width: 100%;
		margin: 0 auto;
	}

	&__slider {
		overflow: hidden !important;
	}

	&__item {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	&__val {
		display: flex;
		font-weight: 700;
		font-size: ac(50px, 38px);
		line-height: ac(60px, 46px);
		color: var(--blue);
	}

	&__line {
		margin-top: ac(8px, 9px);
		width: 88px;
		height: 1px;
		background-color: var(--pastel);
	}

	&__title {
		margin-top: 9px;
		font-weight: 600;
		font-size: ac(20px, 16px);
		line-height: ac(24px, 19px);
	}

	&--org {
		padding-top: ac(150px, 80px);
	}

	@mixin mob {
		&__wrapper {
			margin-right: 0;
		}

		&__item {
			max-width: 100%;
			margin-right: 0;
		}
	}

	@mixin mob-sm {

	}
}
